import Priviledge from './access&permissions/priviledge';
import PlaceOrder from './views/order-management/PlaceOrder';
import CreateOffer from './views/CreateOffer';
import ShopAllocation from './views/retailer-managment/ShopAllocation';
import RetailerList from './views/retailer-managment/RetailerList';
import Setting from './views/retailer-managment/Setting';
import RetailerFigures from './views/retailer-managment/RetailerFigures';
import Dashboard from './views/Dashboard';
import RetailerOrderDetails from './views/retailer-managment/RetailerOrderDetails';
import Login from './views/Login';
import BulkCouponCreation from './views/retailer-managment/BulkCouponCreation';
import StoresReview from './views/retailer-managment/StoresReview';
import ReviewPanel from './views/retailer-managment/ReviewPanel';
import CustomerReview from './views/retailer-managment/CustomerReview';
import LLDaily from './views/daily/LLDaily';
import Banners from './views/daily/Banners';
import ShareOfferCard from './views/daily/ShareOfferCard';
import SmsSent from './views/daily/SmsSent';
import PendingSettlementsStore from './views/settlements/PendingSettlementsStore';
import PendingSettlements from './views/settlements/PendingSettlements';
import ReconciledSettlements from './views/settlements/ReconciledSettlements';
import Feeds from './views/feeds/feeds';
import GenericBulkUpload from './views/generic-bulk-upload/GenericBulkUpload';
import CouponListing from './views/coupons/CouponListing';
import CouponDetails from './views/coupons/CouponDetails';
import CouponTracker from './views/coupons/CouponTracker';
import ReferralCheck from './views/referral-check/ReferralCheck';
import PriceUpdate from './views/catelog-management/PriceUpdate';
import ProductAdd from './views/catelog-management/ProductAdd';
import StoreAggregatedJobStatus from './views/catelog-management/StoreJobStatus';
import ShopLogoReview from './views/retailer-managment/ShopLogoReview';
import RetailerStoryReview from './views/review-panal/RetailerStoryReview';
import FlashSaleDetailed from './views/flash-sale/FlashSaleDetailed';
import FlashSaleAggregated from './views/flash-sale/FlashSaleAggregated';
import Stories from './views/daily/Stories';
import Customer from './views/customer-management/Customer';
import ProductBulkUpload from './views/catelog-management/ProductBulkUpload';
import RefundDashboard from './views/refunds/RefundDashboard';
import QueryTracker from './views/customer-management/QueryTracker';
import SmartCatalogues from './views/smart-catalogue/SmartCatalogues';
import SmartCatalogueMapping from './views/smart-catalogue/SmartCatalogueMapping';
import LibraryProducts from './views/smart-catalogue/LibraryProducts';
import LibraryProductRegionalPrice from './views/smart-catalogue/LibraryProductRegionalPrice';
import SmartCataloguesStore from './views/smart-catalogue/SmartCataloguesStore';
import BackgroundJobs from './views/smart-catalogue/BackgroundJobs';
import LeadManagement from './views/lead-management/LeadManagement';
import ExportCleanupTool from './views/smart-catalogue/ExportCleanupTool';
import ExportCatalogProductDetails from './views/smart-catalogue/ExportCatalogProductDetails';
import CustomerPanel from './views/customer-management/CustomerPanel';
import Orders from './views/order-management/Orders';
import OrderDetails from './views/order-management/OrderDetails';
import FraudManagement from './views/fraud-management/FraudManagement';
import OMStatusTracker from './views/om-status-tracker/OMStatusTracker';
import ShopCataloguePage from './views/smart-catalogue/ShopCataloguePage';
import StoreResurrection from './views/store-resurrection/StoreResurrection';
import LeadershipBoard from './views/leadership-board/LeadershipBoard';
import CampaignAnalytics from './views/campaign-analytics/CampaignAnalytics';
import CartAbandonUsers from './views/cart-abandon-users/CartAbandonUsers';
import AbandonCartDetails from './views/cart-abandon-users/AbandonCartDetails';
import Campaign from './views/coupon-engine/Campaign';
import ViewCampaign from './views/coupon-engine/ViewCampaign';
import RetailerCatalogueFiles from './views/smart-catalogue/RetailerCatalogueFiles';
import CallMapping from './views/communications/Sendbird/CallMapping';
import BrandSamplingCampaignListing from './views/brand-sampling-campaign-management/BrandSamplingCampaignListing';
import CampaignDetails from './views/brand-sampling-campaign-management/CampaignDetails';
import Brands from './views/marketplace/Brands';
import BrandOrders from './views/marketplace/BrandOrders';
import BrandDetails from './views/marketplace/BrandDetails';
import BrandOrderDetails from './views/marketplace/BrandOrderDetails';
import BrandsProducts from './views/marketplace/BrandProducts';
import BrandBanners from './views/brand-banner-management/BrandBanners';
import FestiveCategories from './views/festive-categories/FestiveCategories';
import FinanceDashboard from './views/finance-dashboard/FinanceDashboard';
import PlatformFee from './views/platform-fee/PlatformFee';
import FlashSaleCampaign from './views/flash-sale-campaign/FlashSaleCampaign';
import StoreManagement from './views/store-management/StoreManagement';
import LLPlusOrders from './views/ll-plus-management/LLPlusOrders';
import SpecialNotes from './views/special-notes/SpecialNotes';

const {
  RETAILER,
  ORDERS,
  // PLACE_ORDER,
  SHOP_ALLOCATION,
  CREATE_OFFER,
  RETAILER_DETAILS,
  ORDER_DETAILS,
  RETAILER_ORDER_DETAILS,
  DELIVERY_SETTING,
  SIGNIN,
  DASHBOARD,
  BULK_COUPON_CREATION,
  SHOP_REVIEW,
  CUSTOMERS_REVIEW,
  REVIEW_PANEL,
  BANNERS,
  LL_DAILY,
  SHARE_OFFER_CARD,
  SMS_SENT,
  PENDING_SETTLEMENTS,
  RECONCILED_SETTLEMENTS,
  FEEDS,
  PENDING_SETTLEMENTS_STORE,
  GENERIC_BULK_UPLOAD,
  COUPONS,
  COUPON_DETAILS,
  REFERRAL_CHECK,
  PRICE_UPDATE,
  PRODUCT_ADD,
  STORE_WISE_JOB_STATUS,
  SHOP_LOGO_REVIEW,
  RETAILER_STORY_REVIEW,
  FLASH_SALE_DETAILED,
  FLASH_SALE_AGGREGATED,
  STORIES,
  CUSTOMER,
  PRODUCT_BULK_UPLOAD,
  COUPON_TRACKER,
  QUERY_TRACKER,
  REFUND_DASHBOARD,
  SMART_CATALOGUES,
  SMART_CATALOGUES_STORE,
  SMART_CATALOGUE_MAPPINGS,
  LIBRARY_PRODUCTS,
  LIBRARY_PRODUCT_REGIONAL_PRICE,
  BACKGROUND_JOBS,
  LEAD_MANAGEMENT,
  EXPORT_CLEANUP_TOOL,
  SHOP_CATALOGUE_PAGE,
  EXPORT_CATALOG_PRODUCTS,
  CUSTOMER_PANEL,
  FRAUD_MANAGEMENT,
  OM_STATUS_TRACKER,
  STORE_RESURRECTION,
  LEADERSHIP_BOARD,
  CAMPAIGN_ANALYTICS,
  CART_ABANDON_USER,
  // CART_ABANDON_DETAILS,
  COUPON_ENGINE,
  CAMPAIGN_DETAILS,
  RETAILER_CATALOGUE_FILES,
  CX_RX_CALL_MAPPING,
  BRAND_SAMPLING_CAMPAIGN,
  BRAND_SAMPLING_CAMPAIGN_DETAILS,
  MARKETPLACE_BRANDS,
  MARKETPLACE_ORDERS,
  MARKETPLACE_BRAND_DETAILS,
  MARKETPLACE_ORDER_DETAILS,
  MARKETPLACE_BRAND_PRODUCTS,
  BRAND_BANNERS,
  FESTIVE_CATEGORIES,
  FINANCE_SETTLEMENT_DASHBOARD,
  PLATFORM_FEE_DASHBOARD,
  FLASH_SALE_CAMPAIGN,
  STORE_MANAGEMENT,
  LL_PLUS_PURCHASES,
  SPECIAL_NOTES,
} = Priviledge;

const routes = [
  {
    key: CREATE_OFFER,
    path: '/create-offer',
    component: CreateOffer,
  },
  {
    key: SHOP_ALLOCATION,
    path: '/shop-allocation',
    component: ShopAllocation,
  },
  {
    key: RETAILER_DETAILS,
    path: '/retailer/:code',
    component: RetailerFigures,
  },
  {
    key: RETAILER,
    path: '/retailer',
    component: RetailerList,
  },
  {
    key: DELIVERY_SETTING,
    path: '/retailer-setting',
    component: Setting,
  },
  {
    key: ORDERS,
    path: '/orders',
    component: Orders,
  },
  // {
  //   key: PLACE_ORDER,
  //   path: '/place-order',
  //   component: PlaceOrder,
  // },
  {
    key: ORDER_DETAILS,
    path: '/order-details/:orderId',
    component: OrderDetails,
  },
  {
    key: RETAILER_ORDER_DETAILS,
    path: '/order/:orderId',
    component: RetailerOrderDetails,
  },
  {
    key: BULK_COUPON_CREATION,
    path: '/coupon-creation',
    component: BulkCouponCreation,
  },
  {
    key: REVIEW_PANEL,
    path: '/review-panel',
    component: ReviewPanel,
  },
  {
    key: SHOP_REVIEW,
    path: '/stores-review',
    component: StoresReview,
  },
  {
    key: LL_DAILY,
    path: '/lovelocal-daily',
    component: LLDaily,
  },
  {
    key: SHARE_OFFER_CARD,
    path: '/share-offer-card',
    component: ShareOfferCard,
  },
  {
    key: SMS_SENT,
    path: '/sms-sent',
    component: SmsSent,
  },
  {
    key: BANNERS,
    path: '/banners',
    component: Banners,
  },
  {
    key: CUSTOMERS_REVIEW,
    path: '/customers-review',
    component: CustomerReview,
  },
  {
    key: PENDING_SETTLEMENTS,
    path: '/pending-settlements',
    component: PendingSettlements,
  },
  {
    key: RECONCILED_SETTLEMENTS,
    path: '/reconciled-settlements',
    component: ReconciledSettlements,
  },
  {
    key: PENDING_SETTLEMENTS_STORE,
    path: '/pending-settlements-store',
    component: PendingSettlementsStore,
  },
  {
    key: FEEDS,
    path: '/feeds-section',
    component: Feeds,
  },
  {
    key: GENERIC_BULK_UPLOAD,
    path: '/generic-bulk-upload',
    component: GenericBulkUpload,
  },
  {
    key: COUPON_DETAILS,
    path: '/coupon/:couponId',
    component: CouponDetails,
  },
  {
    key: COUPONS,
    path: '/coupon',
    component: CouponListing,
  },
  {
    key: COUPON_TRACKER,
    path: '/coupon-tracker',
    component: CouponTracker,
  },
  {
    key: QUERY_TRACKER,
    path: '/query-tracker',
    component: QueryTracker,
  },
  {
    key: REFERRAL_CHECK,
    path: '/referral-check',
    component: ReferralCheck,
  },
  {
    key: SIGNIN,
    path: '/signin',
    component: Login,
  },
  {
    key: PRODUCT_BULK_UPLOAD,
    path: '/product-bulk-upload',
    component: ProductBulkUpload,
  },
  {
    key: PRICE_UPDATE,
    path: '/price-update',
    component: PriceUpdate,
  },
  {
    key: PRODUCT_ADD,
    path: '/product-add',
    component: ProductAdd,
  },
  {
    key: STORE_WISE_JOB_STATUS,
    path: '/store-wise-job-status',
    component: StoreAggregatedJobStatus,
  },
  {
    key: SHOP_LOGO_REVIEW,
    path: '/shop-logo-review',
    component: ShopLogoReview,
  },
  {
    key: RETAILER_STORY_REVIEW,
    path: '/retailer-story-review',
    component: RetailerStoryReview,
  },
  {
    key: FLASH_SALE_DETAILED,
    path: '/flash-sale-detailed',
    component: FlashSaleDetailed,
  },
  {
    key: FLASH_SALE_AGGREGATED,
    path: '/flash-sale-aggregated',
    component: FlashSaleAggregated,
  },
  {
    key: STORIES,
    path: '/stories',
    component: Stories,
  },
  {
    key: CUSTOMER,
    path: '/customer',
    component: Customer,
  },
  {
    key: REFUND_DASHBOARD,
    path: '/refund-dashboard',
    component: RefundDashboard,
  },
  {
    key: SMART_CATALOGUES,
    path: '/smart-catalogues',
    component: SmartCatalogues,
  },
  {
    key: SMART_CATALOGUES_STORE,
    path: '/smart-catalogues-store',
    component: SmartCataloguesStore,
  },
  {
    key: SMART_CATALOGUE_MAPPINGS,
    path: '/smart-catalogue-map',
    component: SmartCatalogueMapping,
  },
  {
    key: LIBRARY_PRODUCT_REGIONAL_PRICE,
    path: '/library-product-regional-price',
    component: LibraryProductRegionalPrice,
  },
  {
    key: LIBRARY_PRODUCTS,
    path: '/library-products',
    component: LibraryProducts,
  },
  {
    key: BACKGROUND_JOBS,
    path: '/background-jobs',
    component: BackgroundJobs,
  },
  {
    key: LEAD_MANAGEMENT,
    path: '/lead-management',
    component: LeadManagement,
  },
  {
    key: EXPORT_CATALOG_PRODUCTS,
    path: '/export-cleanup-tool/:code',
    component: ExportCatalogProductDetails,
  },
  {
    key: EXPORT_CLEANUP_TOOL,
    path: '/export-cleanup-tool',
    component: ExportCleanupTool,
  },
  {
    key: SHOP_CATALOGUE_PAGE,
    path: '/shop-catalogue-page',
    component: ShopCataloguePage,
  },
  {
    key: CUSTOMER_PANEL,
    path: '/customer-panel',
    component: CustomerPanel,
  },
  {
    key: FRAUD_MANAGEMENT,
    path: '/fraud-management',
    component: FraudManagement,
  },
  {
    key: OM_STATUS_TRACKER,
    path: '/om-status-tracker',
    component: OMStatusTracker,
  },
  {
    key: STORE_RESURRECTION,
    path: '/store-resurrection',
    component: StoreResurrection,
  },
  {
    key: LEADERSHIP_BOARD,
    path: '/leadership-board',
    component: LeadershipBoard,
  },
  {
    key: CAMPAIGN_ANALYTICS,
    path: '/campaign-analytics',
    component: CampaignAnalytics,
  },
  {
    key: CART_ABANDON_USER,
    path: '/cart-abandon-users',
    component: CartAbandonUsers,
  },
  // {
  //   key: CART_ABANDON_DETAILS,
  //   path: '/cart-abandon-details',
  //   component: AbandonCartDetails,
  // },
  {
    key: CAMPAIGN_DETAILS,
    path: '/campaign/:campaignId',
    component: ViewCampaign,
  },
  {
    key: COUPON_ENGINE,
    path: '/campaign',
    component: Campaign,
  },
  {
    key: RETAILER_CATALOGUE_FILES,
    path: '/retailer-catalogue-files',
    component: RetailerCatalogueFiles,
  },
  {
    key: CX_RX_CALL_MAPPING,
    path: '/cx-rx-call-mapping',
    component: CallMapping,
  },
  {
    key: BRAND_SAMPLING_CAMPAIGN_DETAILS,
    path: '/brand-sampling-campaign-management/:campaignId',
    component: CampaignDetails,
  },
  {
    key: BRAND_SAMPLING_CAMPAIGN,
    path: '/brand-sampling-campaign-management',
    component: BrandSamplingCampaignListing,
  },
  {
    key: MARKETPLACE_BRAND_PRODUCTS,
    path: '/marketplace-brands/:brandId/products',
    component: BrandsProducts,
  },
  {
    key: MARKETPLACE_BRAND_DETAILS,
    path: '/marketplace-brands/:brandId',
    component: BrandDetails,
  },
  {
    key: MARKETPLACE_BRANDS,
    path: '/marketplace-brands',
    component: Brands,
  },
  {
    key: MARKETPLACE_ORDER_DETAILS,
    path: '/marketplace-orders/:orderId',
    component: BrandOrderDetails,
  },
  {
    key: MARKETPLACE_ORDERS,
    path: '/marketplace-orders',
    component: BrandOrders,
  },
  {
    key: BRAND_BANNERS,
    path: '/brand-banners',
    component: BrandBanners,
  },
  {
    key: FESTIVE_CATEGORIES,
    path: '/festive-categories',
    component: FestiveCategories,
  },
  {
    key: FINANCE_SETTLEMENT_DASHBOARD,
    path: '/finance-settlement-dashboard',
    component: FinanceDashboard,
  },
  {
    key: PLATFORM_FEE_DASHBOARD,
    path: '/platform-fee-dashboard',
    component: PlatformFee,
  },
  {
    key: FLASH_SALE_CAMPAIGN,
    path: '/flash-sale-campaign',
    component: FlashSaleCampaign,
  },
  {
    key: STORE_MANAGEMENT,
    path: '/store-management',
    component: StoreManagement,
  },
  {
    key: LL_PLUS_PURCHASES,
    path: '/ll-plus-purchases',
    component: LLPlusOrders,
  },
  {
    key: SPECIAL_NOTES,
    path: '/special-notes',
    component: SpecialNotes,
  },
  {
    key: DASHBOARD,
    path: '/',
    component: Dashboard,
  },
];

export default routes;
