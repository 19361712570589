import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import CustomModal from './CustomModal';
import ErrorHandler from './ErrorHandler';
import { crmCommunication } from '../../assets/api/axios';

class WhatsAppTemplate extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      error: false,
      whatsAppTemplate: '',
      whatsAppContent: null,
      processingPatch: false,
      patchError: false,
      patchErrorMsg: '',
    };
  }

  componentDidMount = () => {
    this.loadWhatsAppContent();
  }

  loadWhatsAppContent = () => {
    const {
      phoneNumber, templateType = 'WHATSAPP_TEMPLATE', orderId = '', storeName = '',
    } = this.props;
    crmCommunication(
      'GET',
      {
        type: templateType,
        phoneNumber,
        orderId,
        storeName,
      },
    ).then((res) => {
      this.setState({
        whatsAppContent: res.data,
        loading: false,
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });
  }

  handleSendWhatsAppMsg = () => {
    const {
      onHide, phoneNumber, handleRequestProcessing, templateType = 'WHATSAPP_TEMPLATE', orderId = '', storeName = '',
    } = this.props;
    const { whatsAppTemplate } = this.state;
    crmCommunication(
      'POST',
      {},
      {
        templateId: whatsAppTemplate.header,
        type: templateType,
        phoneNumber: String(phoneNumber),
        orderId: Number(orderId),
        storeName,
      },
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          processingPatch: false,
        }, () => {
          onHide();
          handleRequestProcessing();
        });
      } else {
        throw new Error();
      }
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong';
      if (
        err
        && err.response
        && err.response.status === 400
        && err.response.data
        && err.response.data[0].server_message
      ) {
        errorMsg = err.response.data[0].server_message;
      }
      this.setState({
        processingPatch: false,
        patchError: true,
        patchErrorMsg: errorMsg,
      });
    });
  }

  reset = () => {
    this.setState({
      loading: false,
      error: false,
      whatsAppTemplate: '',
      whatsAppContent: null,
      processingPatch: false,
      patchError: false,
    });
  }

  render() {
    const { show, onHide } = this.props;
    const {
      loading, error, whatsAppTemplate, whatsAppContent,
      processingPatch, patchError, patchErrorMsg,
    } = this.state;

    const body = (
      <>
        {(() => {
          let showcase = null;
          if (!whatsAppContent && loading) {
            showcase = (
              <div
                className="text-center p-4"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          } else if (!whatsAppContent && !loading && error) {
            showcase = (
              <ErrorHandler
                retryLogic={() => {
                  this.setState({
                    loading: true,
                    error: false,
                  }, () => {
                    this.loadWhatsAppContent();
                  });
                }}
              />
            );
          } else if (whatsAppContent) {
            showcase = (
              <Container>
                <Row>
                  <Col xs={24} className="mt-2 p-2 fs-2 text-center">
                    <b>WhatsApp</b>
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col xs={24}>
                    <b>WhatsApp Template</b>
                    <span className="text-danger">*</span>
                  </Col>
                  <Col xs={24} className="pt-2">
                    <select
                      name="whatsAppTemplate"
                      className="form-control"
                      onChange={(event) => {
                        if (event.target.value !== '') {
                          this.setState({
                            whatsAppTemplate: JSON.parse(event.target.value),
                          });
                        }
                      }}
                    >
                      <option value="">Select</option>
                      {
                        whatsAppContent.data.map((content) => (
                          <option value={JSON.stringify(content)} key={content.header}>
                            {content.header}
                          </option>
                        ))
                      }
                    </select>
                  </Col>
                </Row>

                <Row className="pt-4">
                  <Col xs={24}>
                    <b>Message</b>
                  </Col>
                  <Col xs={24} className="pt-2">
                    {
                      (whatsAppTemplate && whatsAppTemplate.message) || '--'
                    }
                  </Col>
                </Row>

                <Row className="py-2">
                  <Col xs={24} className="text-center">
                    {processingPatch
                      ? (
                        <Spinner
                          variant="primary"
                          animation="border"
                        />
                      ) : (
                        <>
                          <Button
                            variant="primary"
                            block
                            onClick={() => {
                              this.setState({
                                processingPatch: true,
                                patchError: false,
                              }, () => {
                                this.handleSendWhatsAppMsg();
                              });
                            }}
                            disabled={!whatsAppTemplate}
                          >
                            Send
                          </Button>
                          {!processingPatch && patchError && (
                            <div className="py-2 text-danger">
                              <b>{patchErrorMsg}</b>
                            </div>
                          )}
                        </>
                      )}
                  </Col>
                </Row>
              </Container>
            );
          }
          return showcase;
        })()}
      </>
    );

    return (
      <CustomModal
        show={show}
        title=" "
        body={body}
        onHide={onHide}
        closeButton
        autoSize
        backdrop={loading
          || error || 'static'}
        reset={this.reset}
      />
    );
  }
}

WhatsAppTemplate.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  handleRequestProcessing: PropTypes.func.isRequired,
  phoneNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default WhatsAppTemplate;
