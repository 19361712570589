import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import { Spinner } from 'react-bootstrap';
import Axios from 'axios';
import { retailerReviews } from '../../assets/api/axios';
import {
  ErrorHandler, CustomTable,
} from '../../component/common';
import { ProcessingStatus } from '../../component/derived/table-list';
import { Utils } from '../../utilities';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    ...param, l, p,
  });
};

class Reviews extends React.Component {
  constructor(props) {
    const param = getQueryParams(props.history.location.search);
    super();
    this.state = {
      rowsPerPage: param.l,
      page: param.p,
      param,
      reviews: null,
      loading: true,
      error: false,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  handleLoad = (data = {}) => {
    const { history, retailerDetails } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p,
    } = newParam;
    retailerReviews('get', retailerDetails.code,
      {
        page: p,
        limit: l,
      }, this.source.token)
      .then((res) => {
        this.setState({
          rowsPerPage: l,
          page: p,
          param: { ...newParam },
          loading: false,
          error: false,
          reviews: res.data,
        }, () => {
          if (
            Object.keys(newParam).find((key) => (newParam[key] !== param[key]))
          ) {
            Object.keys(newParam).forEach((item) => {
              if (!newParam[item]) {
                delete newParam[item];
              }
            });
            history.push({
              path: pathname,
              search: QueryString.stringify(newParam),
            });
          }
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onCancel = () => {
    const {
      loading, param,
    } = this.state;
    const {
      l, p,
    } = param;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source;
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
    });
  }

  render() {
    const {
      loading, error, param, reviews, rowsPerPage, page,
    } = this.state;
    const headers = [
      {
        key: 'createdOn',
        displayText: 'Created',
        renderer: (data) => Utils.dateString(data.createdOn),
      },
      {
        key: 'customerName',
        displayText: 'Customer',
      },
      {
        key: 'customerPhoneNumber',
        displayText: 'Customer Phone',
      },
      {
        key: 'rating',
        displayText: 'Rating',
      },
      {
        key: 'content',
        displayText: 'Content',
      },
      {
        key: 'reply',
        displayText: 'Reply',
      },
    ];

    if (!reviews && loading) {
      return (
        <div
          className="d-flex align-items-center
           justify-content-center h-100"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!reviews && !loading && error) {
      return (
        <div
          className="d-flex align-items-center
           justify-content-center h-100"
        >
          <ErrorHandler
            retryLogic={() => {
              this.setState({
                loading: true,
                error: false,
              }, () => {
                this.handleJourneyLoad();
              });
            }}
          />
        </div>
      );
    }

    if (reviews) {
      return (
        <div
          id="retailer-overview"
          className="table-list"
        >
          <ProcessingStatus
            show={loading || error}
            loading={loading}
            error={error}
            onRetry={() => this.retry()}
            onCancel={this.onCancel}
          />
          <div>
            <CustomTable
              headers={headers}
              content={reviews.data}
              keyField="id"
              l={param.l}
              p={param.p}
              rowsPerPage={rowsPerPage}
              page={page}
              totalItems={reviews.count}
              hasPrev={reviews.prev}
              hasNext={reviews.next}
              onNext={this.onNext}
              onPrev={this.onPrev}
              onSubmitPage={this.onSubmitPage}
              onSubmitRowsPerPage={this.onSubmitRowsPerPage}
              updateRowsPageInput={this.handleRowsPageInput}
            />
          </div>
        </div>
      );
    }
    return null;
  }
}

Reviews.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  retailerDetails: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
};

export default Reviews;
