import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import {
  Container, Row, Col, Spinner, Button,
} from 'react-bootstrap';
import {
  ErrorHandler, CustomTable, DatePicker,
} from '../../component/common';
import { Utils } from '../../utilities';
import { ProcessingStatus } from '../../component/derived/table-list';
import { retailerCallLogs } from '../../assets/api/axios';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, startDate, endDate,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  startDate = Number(startDate) || '';
  endDate = Number(endDate) || '';
  return ({
    ...param, l, p, startDate, endDate,
  });
};

const getMetaData = (data) => (
  <Row
    className="mx-n2 py-n1"
  >
    {data.map((item) => (
      <Col
        key={item.header}
        xs={12}
        md={8}
        lg={4}
        className="px-2 py-1 text-medium"
      >
        <div
          className="p-2 fs-01 h-100 meta-data"
        >
          <div
            className="font-weight-bold fs-0"
          >
            {`${item.content}` || '---'}
          </div>
          <div>
            {item.header}
          </div>
        </div>
      </Col>
    ))}
  </Row>
);

class CallLogs extends React.Component {
  constructor(props) {
    const param = getQueryParams(props.history.location.search);
    super();
    this.state = {
      rowsPerPage: param.l,
      page: param.p,
      loading: false,
      error: false,
      callLogs: null,
      param,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  handleLoad = (data = {}) => {
    const { history, retailerDetails } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, startDate, endDate,
    } = newParam;
    retailerCallLogs('get', retailerDetails.code,
      {
        page: p,
        limit: l,
        startDate,
        endDate,
      }, this.source.token)
      .then((res) => {
        this.setState({
          callLogs: { ...res.data },
          loading: false,
          rowsPerPage: l,
          page: p,
          param: { ...newParam },
        }, () => {
          if (
            Object.keys(newParam).find((key) => (newParam[key] !== param[key]))
          ) {
            Object.keys(newParam).forEach((item) => {
              if (!newParam[item]) {
                delete newParam[item];
              }
            });
            history.push({
              path: pathname,
              search: QueryString.stringify(newParam),
            });
          }
        });
      }).catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });
    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleDateChange = (startDate, endDate) => {
    const { param } = this.state;
    if (startDate !== param.startDate
      || endDate !== param.endDate) {
      this.handleRequestProcessing({
        startDate,
        endDate,
        p: 1,
      });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onCancel = () => {
    const {
      loading, param,
    } = this.state;
    const {
      l, p,
    } = param;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source;
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
    });
  }

  resetFilters = () => {
    const { param } = this.state;
    if (param.startDate) {
      this.handleRequestProcessing({
        ...getQueryParams({ p: 1, startDate: '', endDate: '' }),
      });
    }
  }

  render() {
    const {
      loading, error,
      callLogs, param, rowsPerPage, page,
    } = this.state;
    const { retailerDetails } = this.props;

    const headers = [
      {
        key: 'createdOn',
        displayText: 'Timestamp',
        renderer: (data) => Utils.dateString(data.createdOn),
      },
      {
        key: 'callFrom',
        displayText: 'Caller',
      },
      {
        key: 'callPurpose',
        displayText: 'Call Purpose',
      },
      {
        key: 'status',
        displayText: 'Call Status',
      },
      {
        key: 'startTime',
        displayText: 'Start Time',
        renderer: (data) => Utils.dateString(data.startTime),
      },
      {
        key: 'endTime',
        displayText: 'End Time',
        renderer: (data) => Utils.dateString(data.endTime),
      },
      {
        key: 'callComment',
        displayText: 'Call Comment',
      },
      {
        key: 'secComment',
        displayText: 'Sec Comment',
      },
      {
        key: 'openComment',
        displayText: 'Open Comment',
      },
      {
        key: 'callBackDate',
        displayText: 'Call Back Date',
        renderer: (data) => Utils.dateString(data.callBackDate),
      },
      {
        key: 'recordingUrl',
        displayText: 'Recording URL',
        renderer: (data) => (
          <Button
            variant="link"
            className="p-0 fs-01"
            onClick={() => {
              window.open(data.recordingUrl);
            }}
            disabled={!data.recordingUrl}
          >
            {data.recordingUrl || '--'}
          </Button>
        ),
      },
      {
        key: 'duration',
        displayText: 'Duration',
      },
    ];

    if (!callLogs && loading) {
      return (
        <div
          className="h-100 d-flex align-items-center
             justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!callLogs && !loading && error) {
      return (
        <div
          className="h-100 d-flex align-items-center
             justify-content-center"
        >
          <ErrorHandler
            retryLogic={this.handleRequestProcessing}
          />
        </div>
      );
    }

    if (callLogs) {
      return (
        <div
          id="call-logs"
          className="bg-white table-list"
        >
          <ProcessingStatus
            show={loading || error}
            loading={loading}
            error={error}
            onRetry={() => this.retry()}
            onCancel={this.onCancel}
          />
          <Container
            fluid
            className="mx-0 px-0"
          >
            <Row
              className="mx-0"
            >
              <Col
                xs={24}
                className="px-0 mb-2 filter-section bg-white"
              >
                <Row
                  className="mx-0"
                >
                  <Col
                    xs="auto"
                    className="px-2 py-1"
                  >
                    <DatePicker
                      isDateRange
                      onApply={(dateRange) => {
                        this.handleDateChange(
                          new Date(dateRange.startDate).getTime(),
                          new Date(dateRange.endDate).getTime(),
                        );
                      }}
                      startDate={param.startDate}
                      endDate={param.endDate}
                      minDate={retailerDetails.createdOn}
                      onClear={() => {
                        this.handleRequestProcessing({
                          startDate: '', endDate: '',
                        });
                      }}
                    />
                  </Col>
                  <Col
                    xs="auto"
                    className="py-1 ml-auto px-3"
                  >
                    <Button
                      variant="outline-primary"
                      className="fs-01"
                      onClick={this.resetFilters}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={24}
                className="px-2 mb-3"
              >
                {getMetaData([
                  {
                    header: 'Total Calls Attempted:',
                    content: callLogs.count,
                  },
                  {
                    header: 'Total Connected Calls :',
                    content: callLogs.connectedCalls,
                  },
                  {
                    header: 'Total Non Contactable Call:',
                    content: callLogs.notConnectedCalls,
                  },
                  {
                    header: 'Welcome Call Status:',
                    content: callLogs.welcomeCallStatus,
                  },
                  {
                    header: 'Courtesy Call Frequency:',
                    content: callLogs.courtesyCallFrequency,
                  },
                ])}
              </Col>
              <Col
                xs={24}
                className="px-0"
              >
                <CustomTable
                  headers={headers}
                  content={callLogs.data}
                  keyField="id"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={callLogs.count}
                  hasPrev={callLogs.prev}
                  hasNext={callLogs.next}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    return null;
  }
}

CallLogs.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  retailerDetails: PropTypes.shape({
    code: PropTypes.string,
    createdOn: PropTypes.string,
  }).isRequired,
};

export default CallLogs;
