import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import {
  Container, Row, Col, Spinner, Button,
} from 'react-bootstrap';
import {
  Svg, ErrorHandler, CustomTable,
  CustomModal,
} from '../../component/common';
import { Constant, Utils } from '../../utilities';
import { ProcessingStatus } from '../../component/derived/table-list';
import { retailerDetailsCatalog, productGroupBulkAdd } from '../../assets/api/axios';

const getFileIcon = (link) => (link
  ? (
    <Button
      variant="link"
      className="p-0 fs-01"
      onClick={() => {
        window.open(link, '_blank');
      }}
    >
      <Svg
        svg="file"
        width="1.3rem"
        fill={Constant.Color.PRIMARY}
      />
    </Button>
  )
  : null
);

const getMetaData = (data) => (
  <Row
    className="mx-n2 py-n1"
  >
    {data.map((item) => (
      <Col
        key={item.header}
        xs={12}
        md={8}
        lg={4}
        className="px-2 py-1 text-medium"
      >
        <div
          className="p-2 fs-01 h-100 meta-data"
        >
          <div
            className="font-weight-bold fs-0"
          >
            {`${item.content}` || '---'}
          </div>
          <div>
            {item.header}
          </div>
        </div>
      </Col>
    ))}
  </Row>
);

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    ...param, l, p,
  });
};

class Catalog extends React.Component {
  constructor(props) {
    const param = getQueryParams(props.history.location.search);
    super();
    this.state = {
      rowsPerPage: param.l,
      page: param.p,
      loading: true,
      error: false,
      catalog: null,
      param,
      selectedRowForRollback: null,
      rollbackStatus: '',
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  handleLoad = (data = {}) => {
    const { history, retailerDetails } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p,
    } = newParam;
    retailerDetailsCatalog('get', retailerDetails.code,
      {
        page: p,
        limit: l,
      }, this.source.token)
      .then((res) => {
        this.setState({
          catalog: { ...res.data },
          loading: false,
          rowsPerPage: l,
          page: p,
          param: { ...newParam },
        }, () => {
          if (
            Object.keys(newParam).find((key) => (newParam[key] !== param[key]))
          ) {
            Object.keys(newParam).forEach((item) => {
              if (!newParam[item]) {
                delete newParam[item];
              }
            });
            history.push({
              path: pathname,
              search: QueryString.stringify(newParam),
            });
          }
        });
      }).catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });
    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  rollbackRequest = () => {
    const { selectedRowForRollback } = this.state;
    productGroupBulkAdd(
      'PATCH',
      {
        action: 'ROLLBACK_ADDED_PRODUCTS',
      },
      selectedRowForRollback.adminProcessedId,
    ).then(() => {
      this.setState({
        selectedRowForRollback: null,
      }, () => {
        this.handleLoad();
      });
    }).catch((error) => {
      let errorMsg = 'Error while rolling back. Please try again!';
      if (error && error.response && error.response.data && error.response.data.server_response) {
        errorMsg = error.response.data.server_response;
      }
      this.setState({
        rollbackStatus: errorMsg,
        selectedRowForRollback: null,
      });
    });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onCancel = () => {
    const {
      loading, param,
    } = this.state;
    const {
      l, p,
    } = param;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source;
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
    });
  }

  render() {
    const {
      loading, error, rollbackStatus,
      catalog, param, rowsPerPage, page, selectedRowForRollback,
    } = this.state;

    const headers = [
      {
        key: 'createdOn',
        displayText: 'Timestamp',
        renderer: (data) => Utils.dateString(data.createdOn),
      },
      {
        key: 'adminProcessedUploadType',
        displayText: 'Type',
      },
      {
        key: 'user',
        displayText: 'User',
      },
      {
        key: 'status',
        displayText: 'Status',
      },
      {
        key: 'requestCsvName',
        displayText: 'Retailer File',
      },
      {
        key: 'fileLink',
        displayText: 'File',
        renderer: (data) => getFileIcon(data.fileLink),
      },
      {
        key: 'rollback',
        displayText: 'Rollback Status',
        renderer: (data) => {
          if (!data.canRequestRollBack) {
            return (
              <span>
                {data.rollBackRequestStatus}
              </span>
            );
          }
          return (
            <Button
              variant="outline-primary"
              onClick={() => {
                this.setState({
                  selectedRowForRollback: data,
                }, () => {
                  this.rollbackRequest();
                });
              }}
              className="fs-01"
              disabled={
                selectedRowForRollback
                  ? data.requestId === selectedRowForRollback.requestId
                  : false
              }
            >
              ROLLBACK
            </Button>
          );
        },
      },
    ];

    if (!catalog && loading) {
      return (
        <div
          className="h-100 d-flex align-items-center
             justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!catalog && !loading && error) {
      return (
        <div
          className="h-100 d-flex align-items-center
             justify-content-center"
        >
          <ErrorHandler
            retryLogic={() => this.handleRequestProcessing()}
          />
        </div>
      );
    }

    if (catalog) {
      return (
        <div
          id="catalog"
          className="table-list"
        >
          <CustomModal
            show={rollbackStatus !== ''}
            onHide={() => {
              this.setState({
                rollbackStatus: '',
              });
            }}
            closeButton
            title="Rollback Status"
            body={(
              <div
                className="p-4 fs-2 text-danger"
              >
                {rollbackStatus}
              </div>
            )}
          />
          <ProcessingStatus
            show={loading || error}
            loading={loading}
            error={error}
            onRetry={() => this.retry()}
            onCancel={this.onCancel}
          />
          <Container
            fluid
            className="mx-0 px-0"
          >
            <Row
              className="mx-0"
            >
              <Col
                xs={24}
                className="px-2 mb-3"
              >
                {getMetaData([
                  {
                    header: 'Total SKUs:',
                    content: catalog.meta.totalSKU,
                  },
                  {
                    header: 'Total Serviceable SKUs:',
                    content: catalog.meta.serviceableSKU,
                  },
                  {
                    header: 'Total Unserviceable SKUs :',
                    content: catalog.meta.unserviceableSKU,
                  },
                  {
                    header: 'Catalog Last Updated On',
                    content: Utils.dateString(catalog.meta.catalogLastUpdatedOn),
                  },
                ])}
              </Col>
              <Col
                xs={24}
                className="px-0"
              >
                <CustomTable
                  headers={headers}
                  content={catalog.data}
                  keyField="requestId"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={catalog.count}
                  hasPrev={catalog.prev}
                  hasNext={catalog.next}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    return null;
  }
}

Catalog.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  retailerDetails: PropTypes.shape({
    code: PropTypes.string,
    createdOn: PropTypes.string,
  }).isRequired,
};

export default Catalog;
