import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import {
  Spinner, Container, Row, Col, Form, InputGroup, Button,
} from 'react-bootstrap';
import Select from 'react-select';
import Axios from 'axios';
import {
  productGroupBulkAdd,
  productGroups as getProductGroups,
  productGroupProducts,
} from '../../assets/api/axios';
import {
  ErrorHandler, CustomTable, Svg, CustomDropdown, CustomModal,
} from '../../component/common';
import { ProcessingStatus } from '../../component/derived/table-list';
import { Constant } from '../../utilities';
import Permission from '../../access&permissions/permission';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, q, spg, action,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  q = q || '';
  spg = spg || '';
  action = action || '';
  return ({
    ...param, l, p, q, spg, action,
  });
};

class BulkProductAddition extends React.Component {
  constructor(props) {
    const param = getQueryParams(props.history.location.search);
    super();
    this.state = {
      rowsPerPage: param.l,
      page: param.p,
      param,
      productGroups: null,
      loadingProductGroups: true,
      errorProductGroups: false,
      products: null,
      loadingProducts: false,
      errorProducts: false,
      searchText: param.q,
      excludedProductsIds: [],
      loading: false,
      error: false,
      showSuccess: false,
      selectedIds: null,
    };
    this.source = Axios.CancelToken.source();
    this.retry = () => {};
  }

  componentDidMount = () => {
    this.handleLoadProductGroups();
  }

  handleChangeProductGroup = (data) => {
    this.setState(
      () => ({
        products: null,
        loadingProducts: data.spg !== '',
        errorProducts: false,
      }),
      () => {
        const { param } = this.state;
        if (data.spg === '') {
          const { history } = this.props;
          history.push({
            path: history.location.pathname,
            search: QueryString.stringify(param),
          });
          return;
        }
        this.handleRequestProcessing({
          ...data, p: 1,
        });
      },
    );
  }

  handleLoadProductGroups = () => {
    getProductGroups('get')
      .then((res) => {
        this.setState({
          loadingProductGroups: false,
          errorProductGroups: false,
          productGroups: [
            {
              label: 'Select Product Group',
              value: '',
            },
            ...res.data.data.map((item) => ({
              label: item.name,
              value: item.id.toString(),
            })),
          ],
        },
        () => {
          const { param } = this.state;
          this.handleChangeProductGroup({
            spg: param.spg,
          });
        });
      })
      .catch(() => {
        this.setState({
          loadingProductGroups: false,
          errorProductGroups: true,
        });
      });

    this.retry = () => {
      this.setState(
        {
          loadingProductGroups: true,
          errorProductGroups: false,
        },
        this.handleLoadProductGroups,
      );
    };
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, q, spg,
    } = newParam;
    const offset = (p - 1) * l;
    productGroupProducts(
      'get',
      null,
      {
        offset: `${offset}`,
        limit: l,
        searchText: q,
        groupIds: spg,
      },
      this.source.token,
    )
      .then((res) => {
        this.setState({
          rowsPerPage: l,
          page: p,
          param: { ...newParam },
          loadingProducts: false,
          errorProducts: false,
          products: { ...res.data },
          searchText: q,
        }, () => {
          if (
            Object.keys(newParam).find((key) => (newParam[key] !== param[key]))
          ) {
            Object.keys(newParam).forEach((item) => {
              if (!newParam[item]) {
                delete newParam[item];
              }
            });
            history.push({
              path: pathname,
              search: QueryString.stringify(newParam),
            });
          }
        });
      })
      .catch(() => {
        this.setState({
          loadingProducts: false,
          errorProducts: true,
        });
      });

    this.retry = () => {
      this.setState({
        loadingProducts: true,
        errorProducts: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleOnChange = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  onSubmitSearchText = () => {
    const {
      searchText, param,
    } = this.state;
    if (searchText !== param.q) {
      this.handleRequestProcessing({ q: searchText, p: 1 });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loadingProducts } = this.state;
    if (loadingProducts) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      errorProducts: false,
      loadingProducts: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleProductSelection = (e) => {
    const { value, checked } = e.target;
    const { excludedProductsIds } = this.state;
    let newProductIds = [...excludedProductsIds];
    if (checked) {
      newProductIds = newProductIds.filter((id) => id !== parseInt(value, 10));
    } else {
      newProductIds.push(parseInt(value, 10));
    }
    this.setState({
      excludedProductsIds: newProductIds,
    });
  }

  handleChangeAction = (data) => {
    this.setState(
      (state) => ({
        param: {
          ...state.param,
          ...data,
        },
      }),
      () => {
        const { param } = this.state;
        const { history } = this.props;
        history.push({
          path: history.location.pathname,
          search: QueryString.stringify(param),
        });
      },
    );
  }

  handleSubmit = () => {
    const {
      excludedProductsIds,
      param,
    } = this.state;
    const { retailerDetails } = this.props;
    productGroupBulkAdd(
      'post',
      {
        excludedProductsIds,
        bulkAddType: param.action,
        storeCode: retailerDetails.code,
        groupName: param.spg,
      },
      null,
      {
        requestType: 'MULTIPLE',
      },
    )
      .then(() => {
        this.setState({
          showSuccess: true,
          loading: false,
          error: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });

    this.retry = () => {
      this.setState(
        {
          loading: true,
          error: false,
        },
        this.handleSubmit,
      );
    };
  }

  reset = () => {
    const { history } = this.props;
    const param = {
      l: 10,
      p: 1,
      q: '',
      spg: '',
      action: '',
      view: 'bulkProductAddition',
    };
    this.setState({
      rowsPerPage: param.l,
      page: param.p,
      param,
      products: null,
      loadingProducts: false,
      errorProducts: false,
      searchText: param.q,
      excludedProductsIds: [],
      selectedIds: null,
      loading: false,
      error: false,
      showSuccess: false,
    });
    history.push({
      path: history.location.pathname,
      search: QueryString.stringify(param),
    });
  }

  render() {
    const {
      loadingProducts, errorProducts, param, products, rowsPerPage, page,
      searchText, excludedProductsIds, loadingProductGroups, errorProductGroups,
      productGroups, loading, error, showSuccess, selectedIds,
    } = this.state;
    const { history, userPermission } = this.props;

    const headers = [
      {
        key: 'action-dropdown',
        displayText: 'Select',
        renderer: (data) => (
          <div
            className="text-center"
          >
            <Form.Check
              custom
              id={`${data.id}-checkbox`}
              label=""
              value={data.id}
              checked={!excludedProductsIds.includes(data.id)}
              onChange={this.handleProductSelection}
            />
          </div>
        ),
      },
      {
        key: 'id',
        displayText: 'Id',
      },
      {
        key: 'name',
        displayText: 'Name',
        renderer: (data) => (
          <div
            className="w-150p"
          >
            {data.name}
          </div>
        ),
      },
      {
        key: 'mrp',
        displayText: 'MRP',
      },
      {
        key: 'url',
        displayText: 'Image',
        renderer: (data) => (
          <div
            className="product-image-div"
          >
            <img
              src={data.url}
              alt=""
            />
          </div>
        ),
      },
    ];

    if (!productGroups && loadingProductGroups) {
      return (
        <div
          className="d-flex align-items-center
           justify-content-center h-100"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!productGroups && !loadingProductGroups && errorProductGroups) {
      return (
        <div
          className="d-flex align-items-center
           justify-content-center h-100"
        >
          <ErrorHandler
            retryLogic={() => {
              this.setState(
                {
                  loadingProductGroups: true,
                  errorProductGroups: false,
                },
                this.handleLoadProductGroups,
              );
            }}
          />
        </div>
      );
    }

    const getProductShowcase = () => {
      if (productGroups) {
        if (loadingProducts) {
          return (
            <div
              className="d-flex align-items-center
              justify-content-center h-100"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          );
        }
        if (errorProducts) {
          return (
            <div
              className="d-flex align-items-center
              justify-content-center h-100"
            >
              <ErrorHandler
                retryLogic={() => {
                  this.setState({
                    loadingProducts: true,
                    errorProducts: false,
                  }, () => {
                    this.handleLoad();
                  });
                }}
              />
            </div>
          );
        }
        if (products) {
          return (
            <Row
              className="mx-0 table-list"
            >
              <Col
                xs={24}
                className="px-0 filter-section mb-2 bg-white h-50p"
              >
                <Row
                  className="mx-0 align-items-center"
                >
                  <Col
                    xs={24}
                    sm="auto"
                    className="px-2 py-1"
                  >
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          className="rounded-0"
                        >
                          <Svg
                            svg="search"
                            width="1rem"
                            fill={Constant.Color.DARK}
                          />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        className="fs-01 rounded-0"
                        value={searchText}
                        onChange={this.handleOnChange}
                        autoComplete="off"
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            this.onSubmitSearchText();
                          }
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col
                    xs="auto"
                  >
                    <CustomDropdown
                      item={{
                        key: 'action',
                        displayText: 'Action',
                        options: [
                          {
                            label: 'Add Products',
                            value: 'ADD_PRODUCT',
                          },
                          {
                            label: 'Delete Matching Products and Add Again',
                            value: 'DELETE_AND_UPDATE_NEW',
                          },
                        ],
                      }}
                      onChange={this.handleChangeAction}
                      selectedVal={param.action}
                      disabled={!userPermission.includes(Permission.BULK_PRODUCT_ADDITION)}
                    />
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        this.setState(
                          {
                            loading: true,
                            error: false,
                          },
                          this.handleSubmit,
                        );
                      }}
                      disabled={param.action === ''}
                    >
                      Add Products
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={24}
                className="px-0 h-100"
              >
                <CustomTable
                  headers={headers}
                  content={products.results}
                  keyField="id"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={products.count}
                  hasPrev={products.hasPrevious}
                  hasNext={products.hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              </Col>
            </Row>
          );
        }
      }
      return '';
    };

    if (productGroups) {
      return (
        <div
          id="retailer-product"
          className="h-100"
        >
          <CustomModal
            autoSize
            backdrop="static"
            show={showSuccess}
            onHide={this.reset}
            body={(
              <Row
                className="p-3"
              >
                <Col
                  xs={24}
                >
                  Your Request is being processed. You can track the status
                  &nbsp;
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${history.location.pathname}?view=catalog`}
                  >
                    here
                  </a>
                </Col>
                <Col
                  className="border-bottom my-2"
                />
                <Col
                  xs={24}
                  className="text-right"
                >
                  <Button
                    onClick={this.reset}
                  >
                    Ok
                  </Button>
                </Col>
              </Row>
            )}
          />
          <ProcessingStatus
            show={loading || error}
            loading={loading}
            error={error}
            onRetry={() => this.retry()}
            onCancel={() => {}}
          />
          <Container
            fluid
            className="mx-0 px-0 h-100"
          >
            <Row
              className="mx-0 pb-4"
            >
              <Col xs="auto" className="px-2 minw-300p">
                <Select
                  placeholder="Select Product Group Id's"
                  id="selectedGroupIds"
                  onChange={(ids) => {
                    this.setState({
                      selectedIds: ids,
                    }, () => {
                      this.handleChangeProductGroup({ spg: (ids.map((i) => i.value)).join(',') });
                    });
                  }}
                  value={selectedIds}
                  isMulti
                  options={productGroups}
                />
              </Col>
            </Row>
            {getProductShowcase()}
          </Container>
        </div>
      );
    }
    return null;
  }
}

BulkProductAddition.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  retailerDetails: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default BulkProductAddition;
