/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spinner } from 'react-bootstrap';
import Svg from '../../component/common/Svg';
import { dateString, shopTags } from '../../utilities/Utils';
import '../../assets/scss/OrderManagment/PlaceOrder.scss';

const stores = (props) => {
  const {
    selectedStore,
    storeList,
    handleStoreSelection,
    getNextStores,
    loadingStores,
  } = props;

  function handleLazyLoad(event) {
    const {
      scrollHeight,
      scrollTop,
      offsetHeight,
    } = event.target;
    if ((offsetHeight + scrollTop) === scrollHeight
      && !loadingStores && storeList.hasNext) {
      getNextStores();
    }
  }

  return (
    <Row
      // eslint-disable-next-line react/jsx-no-bind
      onScroll={handleLazyLoad}
      className="mx-0 w-100 d-flex justify-content-center store-list"
    >
      {
        storeList.results.map((store) => {
          const tags = shopTags(store.storeMetrics, store.createdOn, Number(store.rating));
          const { primaryTag } = tags;
          delete tags.primaryTag;
          const isSelected = store.code === selectedStore;
          return (
            <Col
              key={store.code}
              xs="auto"
              className={`store-tile p-3 border shadow-sm ${
                isSelected
                  ? 'bg-primary-50 cursor-pointer'
                  : `${primaryTag.color[0]} cursor-pointer`
              }`}
              onClick={() => {
                if (store.isDelivering) {
                  handleStoreSelection(isSelected ? null : store.code);
                }
              }}
            >
              <div
                className="font-weight-bold text-truncate"
              >
                {store.displayName}
              </div>
              <div
                className="d-flex align-items-center"
              >
                <span>
                  {store.code}
                </span>
                &nbsp;|&nbsp;
                <span
                  className="text-success"
                >
                  {store.distance}
                </span>
                &nbsp;|&nbsp;
                <span>
                  &#x2606;&nbsp;
                  {store.rating}
                  (
                  {store.ratingsCount}
                  &nbsp;
                  {store.ratingsCount > 1 ? 'Reviews' : 'Review'}
                  )
                </span>
              </div>
              <div
                className="text-truncate text-info"
              >
                {store.storeType.description}
              </div>
              <div
                className="fs-0 d-flex align-items-center"
              >
                <span>
                  Delivery Charge:
                  &#8377;
                  {store.deliveryChargeToConsumerDistance}
                </span>
                &nbsp;|&nbsp;
                <span>
                  MOV:
                  &#8377;
                  {store.homeDeliveryMinimumAmount}
                </span>
                &nbsp;|&nbsp;
                <span>
                  MOV Penalty:
                  &#8377;
                  {store.movPenaltyAmount}
                </span>
              </div>
              <div
                className="fs-0 d-flex align-items-center"
              >
                <span>
                  Delivery Rate:&nbsp;
                  {store.deliveryRate}
                </span>
                &nbsp;|&nbsp;
                <span>
                  Orders Delivered:&nbsp;
                  {store.ordersDelivered}
                </span>
              </div>
              <div
                className="fs-0 d-flex align-items-center"
              >
                <span>
                  Last Delivered Order Date:&nbsp;
                  {dateString(store.lastDeliveredOrderDate)}
                </span>
                {
                  store.isFraud && (
                    <>
                      &nbsp;|&nbsp;
                      <span className="text-danger font-weight-bold">
                        FRAUD
                      </span>
                    </>
                  )
                }
              </div>
              <div
                className="d-flex align-items-center fs-0"
              >
                <span>
                  {
                    store.pickupOnlyAllowed
                      ? (
                        <span
                          className="text-primary"
                        >
                          <Svg
                            svg="takeAway"
                            fill="#2b2ba0"
                            width="1rem"
                          />
                          &nbsp;
                          Shop Pickup Only
                        </span>
                      )
                      : (
                        <span
                          className="text-primary"
                        >
                          <Svg
                            svg="delivery"
                            fill="#2b2ba0"
                            width="1rem"
                          />
                          &nbsp;
                          Home Delivery
                        </span>
                      )
                  }
                </span>
                &nbsp;|&nbsp;
                <a
                  href={`tel:${store.phoneNumber}`}
                  className="text-success"
                >
                  <Svg
                    svg="supportPhone"
                    stroke="#b8238e"
                    width="1rem"
                  />
                  &nbsp;
                  {store.phoneNumber}
                </a>
              </div>
              <Row className="mx-0 font-weight-bold">
                {
                  Object.keys(tags).map((key) => (
                    <div className="rounded-pill bg-white mr-2 mt-2 p-1">
                      <div className={`rounded-pill ${tags[key].color[0]} shoptag1`}>
                        <div className={`rounded-circle bg-white fs-01 ${tags[key].color[1]} shoptag2`}>
                          {tags[key].shortHand}
                        </div>
                        <div className={`fs-0 ${tags[key].color[0] === 'bg-warning' ? 'text-black' : ''} shoptag3`}>
                          {tags[key].fullForm}
                          &nbsp;-&nbsp;
                          {tags[key].matrics}
                        </div>
                      </div>
                    </div>
                  ))
                }
              </Row>
            </Col>
          );
        })
      }
      {
        storeList.hasNext && (
          <Col xs={24} className="text-center pt-4">
            <Spinner
              variant="primary"
              animation="border"
            />
          </Col>
        )
      }
      {
        (storeList.count === 0) && (
          <Col xs={24} className="text-center pt-2">
            Store Not Found
          </Col>
        )
      }
    </Row>
  );
};

stores.propTypes = {
  selectedStore: PropTypes.string,
  storeList: PropTypes.shape({
    hasNext: PropTypes.bool.isRequired,
    results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  loadingStores: PropTypes.bool.isRequired,
  handleStoreSelection: PropTypes.func.isRequired,
  getNextStores: PropTypes.func.isRequired,
};

stores.defaultProps = {
  selectedStore: null,
  storeList: [],
};

export default stores;
