import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import {
  Container, Row, Col, Spinner, InputGroup, Form, Button,
} from 'react-bootstrap';
import { retailerOrders } from '../../assets/api/axios';
import {
  ErrorHandler, CustomTable, CustomDropdown, Svg, DatePicker,
} from '../../component/common';
import { ProcessingStatus } from '../../component/derived/table-list';
import { Utils, Constant } from '../../utilities';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, paymentMode, paymentStatus, startDate, endDate, deliveryStatus, q,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  q = q || '';
  startDate = Number(startDate) || '';
  endDate = Number(endDate) || '';
  paymentMode = ['CASH', 'DIGITAL'].includes(paymentMode) ? paymentMode : '';
  paymentStatus = ['PENDING', 'PAID'].includes(paymentStatus) ? paymentStatus : '';
  deliveryStatus = ['pending', 'delivered,cancelled',
    'out_for_delivery,modified,ready_to_ship,changes_accepted,changes_rejected',
  ].includes(deliveryStatus) ? deliveryStatus : '';
  return ({
    ...param, l, p, paymentMode, paymentStatus, startDate, endDate, deliveryStatus, q,
  });
};

const getStatusIcon = (status) => (
  status
    ? <span className="text-success">&#10004;</span>
    : <span className="text-danger">&#10006;</span>
);

const getMetaData = (data, handler, selectedVal) => (
  <Row
    className="mx-n2 py-n1"
  >
    {data.map((item) => {
      const isSelected = selectedVal === item.key;
      return (
        <Col
          key={item.key}
          xs={8}
          md={6}
          lg={3}
          className="px-2 py-1"
        >
          <Button
            variant={isSelected ? 'primary' : 'white'}
            className={`d-block p-2 fs-01 h-100 w-100 text-left
            rounded-0 meta-data ${isSelected ? 'pointer-events-none' : 'text-medium'}`}
            onClick={() => (
              item.content && !isSelected
                ? handler(item.key)
                : ''
            )}
            disabled={!item.content}
          >
            <div
              className="fs-0 font-weight-bold"
            >
              {item.content || 0}
            </div>
            <div>
              {item.header}
            </div>
          </Button>
        </Col>
      );
    })}
  </Row>
);

class Order extends React.Component {
  constructor(props) {
    const param = getQueryParams(props.history.location.search);
    super();
    this.state = {
      rowsPerPage: param.l,
      page: param.p,
      loading: true,
      error: false,
      order: null,
      searchText: param.q,
      param,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  handleLoad = (data = {}) => {
    const { history, retailerDetails } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, paymentMode, paymentStatus, startDate, endDate, deliveryStatus, q,
    } = newParam;
    retailerOrders('get', retailerDetails.code,
      {
        // view: 'order',
        page: p,
        limit: l,
        paymentMode,
        paymentStatus,
        startDate,
        endDate,
        deliveryStatus,
        searchText: q,
      }, this.source.token)
      .then((res) => {
        this.setState({
          order: { ...res.data },
          loading: false,
          rowsPerPage: l,
          page: p,
          param: { ...newParam },
        }, () => {
          if (
            Object.keys(newParam).find((key) => (newParam[key] !== param[key]))
          ) {
            Object.keys(newParam).forEach((item) => {
              if (!newParam[item]) {
                delete newParam[item];
              }
            });
            history.push({
              path: pathname,
              search: QueryString.stringify(newParam),
            });
          }
        });
      }).catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });
    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleOnChange = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  }

  handleDateChange = (startDate, endDate) => {
    const { param } = this.state;
    if (startDate !== param.startDate
      || endDate !== param.endDate) {
      this.handleRequestProcessing({
        startDate,
        endDate,
        p: 1,
      });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  onSubmitSearchText = () => {
    const {
      searchText, param,
    } = this.state;
    if (searchText !== param.q) {
      this.handleRequestProcessing({ q: searchText, p: 1 });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onCancel = () => {
    const {
      loading, param,
    } = this.state;
    const {
      l, p,
    } = param;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source;
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
    });
  }

  resetFilters = () => {
    const { param } = this.state;
    if (param.q || param.paymentMode
      || param.paymentStatus || param.startDate
      || param.deliveryStatus) {
      this.handleRequestProcessing({
        q: '', p: 1, paymentMode: '', paymentStatus: '', deliveryStatus: '',
      });
    }
  }

  render() {
    const {
      order, loading, error, rowsPerPage, page, param, searchText,
    } = this.state;
    const { retailerDetails } = this.props;

    const headers = [
      {
        key: 'orderId',
        displayText: 'Order',
        renderer: (data) => {
          return (
            <Link
              to={`/order-details/${data.orderId}`}
            >
              {data.orderId}
            </Link>
          );
        },
      },
      {
        key: 'createdOn',
        displayText: 'Created',
        renderer: (data) => Utils.dateString(data.createdOn),
      },
      {
        key: 'customerNumber',
        displayText: 'Customer',
        renderer: (data) => data.customerPhoneNumber,
      },
      {
        key: 'deliveryStatus',
        displayText: 'Status',
      },
      {
        key: 'paymentMode',
        displayText: 'Payment Mode',
      },
      {
        key: 'paymentStatus',
        displayText: 'Payment Status',
        renderer: (data) => getStatusIcon(data.paymentStatus === 'PAID'),
      },
      {
        key: 'billAmount',
        displayText: 'Total Bill',
      },
      {
        key: 'payableAmount',
        displayText: 'Payable Amount',
      },
      {
        key: 'couponDetail',
        displayText: 'Coupon',
        renderer: (data) => getStatusIcon(data.couponDetail?.code),
      },
      {
        key: 'couponCode',
        displayText: 'Coupon Code',
        renderer: (data) => data.couponDetail?.code || '--',
      },
      {
        key: 'Paid',
        displayText: 'Paid',
        renderer: (data) => getStatusIcon(data.isPaid),
      },
      {
        key: 'redirectedFrom',
        displayText: 'Redirected From',
        renderer: (data) => data.redirectedFrom || '--',
      },
      {
        key: 'redirectedTo',
        displayText: 'Redirected To',
        renderer: (data) => data.redirectedTo || '--',
      },
    ];

    const filterConf = [
      {
        key: 'paymentMode',
        displayText: 'Payment Mode',
        options: [
          {
            label: 'Cash',
            value: 'CASH',
          },
          {
            label: 'Digital',
            value: 'DIGITAL',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
      {
        key: 'paymentStatus',
        displayText: 'Payment Status',
        options: [
          {
            label: 'Paid',
            value: 'PAID',
          },
          {
            label: 'Pending',
            value: 'PENDING',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
      {
        key: 'deliveryStatus',
        displayText: 'Delivery Status',
        options: [
          {
            label: 'New',
            value: 'pending',
          },
          {
            label: 'In Progress',
            value: 'out_for_delivery,modified,ready_to_ship,changes_accepted,changes_rejected',
          },
          {
            label: 'Closed',
            value: 'delivered,cancelled',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
    ];

    if (!order && loading) {
      return (
        <div
          className="h-100 d-flex align-items-center
               justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!order && !loading && error) {
      return (
        <div
          className="h-100 d-flex align-items-center
               justify-content-center"
        >
          <ErrorHandler
            retryLogic={() => this.handleRequestProcessing()}
          />
        </div>
      );
    }

    if (order) {
      return (
        <div
          id="catalog"
          className="table-list"
        >
          <ProcessingStatus
            show={loading || error}
            loading={loading}
            error={error}
            onRetry={() => this.retry()}
            onCancel={this.onCancel}
          />
          <Container
            fluid
            className="mx-0 px-0"
          >
            <Row
              className="mx-0"
            >
              <Col
                xs={24}
                className="px-0 mb-2 bg-white filter-section"
              >
                <Row
                  className="mx-0"
                >
                  <Col
                    xs={24}
                    sm="auto"
                    className="px-2 py-1"
                  >
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          className="rounded-0"
                        >
                          <Svg
                            svg="search"
                            width="1rem"
                            fill={Constant.Color.DARK}
                          />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        className="fs-01 rounded-0"
                        value={searchText}
                        onChange={this.handleOnChange}
                        autoComplete="off"
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            this.onSubmitSearchText();
                          }
                        }}
                      />
                    </InputGroup>
                  </Col>
                  {filterConf.map((item) => (
                    <Col
                      key={item.key}
                      xs="auto"
                      className="px-2 py-1"
                    >
                      <CustomDropdown
                        item={item}
                        onChange={this.handleDropdownChange}
                        selectedVal={param[item.key]}
                      />
                    </Col>
                  ))}
                  <Col
                    xs="auto"
                    className="px-2 py-1"
                  >
                    <DatePicker
                      isDateRange
                      onApply={(dateRange) => {
                        this.handleDateChange(
                          new Date(dateRange.startDate).getTime(),
                          new Date(dateRange.endDate).getTime(),
                        );
                      }}
                      startDate={param.startDate}
                      endDate={param.endDate}
                      minDate={retailerDetails.createdOn}
                      onClear={() => {
                        this.handleRequestProcessing({
                          startDate: '', endDate: '',
                        });
                      }}
                    />
                  </Col>
                  <Col
                    xs="auto"
                    className="py-1 ml-auto px-3"
                  >
                    <Button
                      variant="outline-primary"
                      className="fs-01"
                      onClick={this.resetFilters}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={24}
                className="px-2 mb-3"
              >
                {getMetaData([
                  {
                    key: 'DELIVERED',
                    header: 'Delivered',
                    content: order.DELIVERED,
                  },
                  {
                    key: 'PENDING',
                    header: 'Pending',
                    content: order.PENDING,
                  },
                  {
                    key: 'OUT_FOR_DELIVERY',
                    header: 'Out for Delivery',
                    content: order.OUT_FOR_DELIVERY,
                  },
                  {
                    key: 'READY_TO_SHIP',
                    header: 'Ready to Ship',
                    content: order.READY_TO_SHIP,
                  },
                  {
                    key: 'CHANGES_ACCEPTED',
                    header: 'Changes Accepted',
                    content: order.CHANGES_ACCEPTED,
                  },
                  {
                    key: 'CHANGES_REJECTED',
                    header: 'Changes Rejected',
                    content: order.CHANGES_REJECTED,
                  },
                  {
                    key: 'MODIFIED',
                    header: 'Modified',
                    content: order.MODIFIED,
                  },
                  {
                    key: 'CANCELLED',
                    header: 'Cancelled',
                    content: order.CANCELLED,
                  },
                  {
                    key: 'couponDiscount',
                    header: 'Coupon Discount',
                    content: order.couponDiscount,
                  },
                  {
                    key: 'digitalCollection',
                    header: 'Digital Collection',
                    content: order.digitalCollection,
                  },
                ],
                (deliveryStatus) => {
                  this.handleRequestProcessing({
                    ...param, p: 1, deliveryStatus,
                  });
                },
                param.deliveryStatus)}
              </Col>
              <Col
                xs={24}
                className="px-0"
              >
                <CustomTable
                  headers={headers}
                  content={order.data}
                  keyField="orderId"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={order.count}
                  hasPrev={order.prev}
                  hasNext={order.next}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    return null;
  }
}

Order.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  retailerDetails: PropTypes.shape({
    code: PropTypes.string,
    createdOn: PropTypes.string,
  }).isRequired,
};

export default Order;
