import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Spinner,
} from 'react-bootstrap';
import QueryString from 'query-string';
import { CustomTable, ErrorHandler } from '../../component/common';
import { ProcessingStatus } from '../../component/derived/table-list';
import { dateString } from '../../utilities/Utils';
import { storeCommentLogs } from '../../assets/api/axios';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let { l, p } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    ...param,
    l,
    p,
  });
};

class CommentHistory extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    this.state = {
      loading: true,
      error: false,
      comments: null,
      rowsPerPage: param.l,
      page: param.p,
      param,
    };
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  handleLoad = (data = {}) => {
    const { retailerDetails } = this.props;
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p,
    } = newParam;

    storeCommentLogs(
      'GET',
      {
        page: p,
        limit: l,
        storeCode: retailerDetails.code,
        format: 'table',
      },
    ).then((res) => {
      this.setState({
        loading: false,
        comments: res.data.data,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onCancel = () => {
    const {
      loading, param,
    } = this.state;
    const {
      l, p,
    } = param;
    if (loading) {
      this.source.cancel();
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  render() {
    const {
      loading, error, comments,
      param, rowsPerPage, page,
    } = this.state;

    const headers = [
      {
        key: 'comment',
        displayText: 'Comment',
      },
      {
        key: 'addedBy',
        displayText: 'Added By',
      },
      {
        key: 'addedFrom',
        displayText: 'Added From',
      },
      {
        key: 'createdAt',
        displayText: 'Timestamp',
        renderer: (data) => dateString(data.createdAt),
      },
    ];

    return (
      <>
        {(() => {
          let showcase = null;
          if (!comments && loading) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center
                justify-content-center"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          } else if (!comments && !loading && error) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center
                justify-content-center"
              >
                <ErrorHandler
                  retryLogic={() => this.retry()}
                />
              </div>
            );
          } else if (comments) {
            showcase = (
              <>
                <ProcessingStatus
                  show={loading || error}
                  loading={loading}
                  error={error}
                  onRetry={() => this.retry()}
                  onCancel={this.onCancel}
                />
                <Row
                  className="mx-0 py-2"
                >
                  <Col
                    xs={24}
                    className="px-0 table-section"
                  >
                    <CustomTable
                      headers={headers}
                      content={comments.results}
                      keyField="id"
                      l={param.l}
                      p={param.p}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      totalItems={comments.count}
                      hasPrev={comments.hasPrevious}
                      hasNext={comments.hasNext}
                      onNext={this.onNext}
                      onPrev={this.onPrev}
                      onSubmitPage={this.onSubmitPage}
                      onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                      updateRowsPageInput={this.handleRowsPageInput}
                    />
                  </Col>
                </Row>
              </>
            );
          }
          return showcase;
        })()}
      </>
    );
  }
}

CommentHistory.propTypes = {
  retailerDetails: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default CommentHistory;
