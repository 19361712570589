/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Spinner, Card, Button,
  Form, InputGroup,
} from 'react-bootstrap';
import Axios from 'axios';
import {
  ErrorHandler, CustomModal, Svg, CustomTable,
} from '../../component/common';
import { specialOffer, storeProduct, uploadImage } from '../../assets/api/axios';
import Constant from '../../utilities/Constant';
import { unitsMap } from '../../utilities/Utils';
import { validateRegex } from '../../utilities/FormValidations';
import Permission from '../../access&permissions/permission';

class FreeGift extends React.Component {
  constructor(props) {
    super(props);
    const { userPermission } = props;
    this.canIEdit = userPermission
      && (userPermission.includes(Permission.RETAILER_WRITE)
      || userPermission.includes(Permission.RETAILER_PERMISSION_ON_SPECIAL_OFFER_PAGE));
    this.state = {
      offers: null,
      loading: true,
      error: false,
      addSpendOffer: false,
      submittingSpendOffer: false,
      submittingSpendOfferError: false,
      addingProduct: null,
      storeProducts: null,
      searchText: '',
      loadingProducts: false,
      productsFailure: false,
      selectedproduct: null,
      productQty: 1,
      spendAmount: '',
      offerImage: '',
      deleteOffer: null,
      deletingOffer: false,
      deletingOfferError: false,
      activeTimeout: -1,
      createStory: true,
      offersSearchText: '',
      view: '',
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleOffer();
  }

  handleOffer = () => {
    const { retailerDetails } = this.props;
    const { view, offersSearchText } = this.state;
    const offerTypes = 'SPEND_OFFER';

    const payload = {
      offerType: offerTypes,
      page: 1,
      limit: 30,
    };
    if (view === 'search') {
      payload.searchText = offersSearchText;
    }

    specialOffer(
      'GET',
      retailerDetails.code,
      payload,
    ).then((res) => {
      this.setState({
        offers: res.data,
        loading: false,
        error: false,
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad();
      });
    };
  }

  copySpendOffer = (index) => {
    const { offers } = this.state;
    const { retailerDetails } = this.props;
    const offersList = offers.data;
    offersList[index].creatingStatus = 'loading';
    const offer = offersList[index];
    this.setState({
      offers: {
        ...offers,
        redasults: offersList,
      },
    });

    specialOffer(
      'POST',
      retailerDetails.code,
      {},
      {
        image: offer.image,
        name: offer.name,
        offerType: 'SPEND_OFFER',
        description: offer.description,
        spendAmount: offer.spendAmount,
        libraryProductId1: offer.libraryProductId1,
        createStory: true,
      },
    ).then(() => {
      offersList[index].creatingStatus = 'success';
      this.setState({
        offers: {
          ...offers,
          data: offersList,
        },
      });
    }).catch(() => {
      offersList[index].creatingStatus = 'error';
      this.setState({
        offers: {
          ...offers,
          data: offersList,
        },
      });
    });
  }

  addSpendOffer = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { retailerDetails } = this.props;
    const {
      offerImage, selectedproduct,
      spendAmount, productQty, createStory,
    } = this.state;

    const offerName = `Spend ₹${
      spendAmount
    } & get ${
      productQty > 1 ? ` ${productQty} ` : ''
    }${
      selectedproduct.displayName.trim()
    }${
      selectedproduct.uom && selectedproduct.unit
        ? ` (${selectedproduct.unit}${unitsMap[selectedproduct.uom]})`
        : ''
    } worth ₹${selectedproduct.mrp * productQty} FREE!`;

    this.setState({
      submittingSpendOffer: true,
    });
    specialOffer(
      'POST',
      retailerDetails.code,
      {},
      {
        image: offerImage,
        name: offerName,
        offerType: 'SPEND_OFFER',
        description: offerName,
        spendAmount,
        ...selectedproduct.isLibraryProduct
          ? { libraryProductId1: selectedproduct.id }
          : { retailerProductId1: selectedproduct.id },
        createStory,
      },
    ).then(() => {
      this.setState({
        addSpendOffer: false,
        submittingSpendOffer: false,
        selectedproduct: null,
        productQty: 1,
        spendAmount: '',
        offerImage: '',
        createStory: true,
      }, () => {
        this.handleOffer();
      });
    }).catch(() => {
      this.setState({
        submittingSpendOffer: false,
        submittingSpendOfferError: true,
      });
      return false;
    });
  }

  handleChangeOfferStatus = (activeStatus, offer) => {
    const { retailerDetails } = this.props;
    const { offers } = this.state;
    const index = offers.data.findIndex((value) => value.id === offer.id);
    specialOffer(
      'PATCH',
      retailerDetails.code,
      {},
      {
        id: offer.id,
        offerType: 'SPEND_OFFER',
        activeStatus,
      },
    ).then((res) => {
      if (res.status === 200) {
        this.setState((state) => ({
          offers: {
            ...state.offers,
            data: Object.assign(
              [...state.offers.data],
              {
                [index]: {
                  ...state.offers.data[index],
                  activeStatus,
                },
              },
            ),
          },
        }));
      } else {
        throw new Error();
      }
    })
      .catch(() => {});
  }

  handleDeleteOffer = (offer) => {
    const { retailerDetails } = this.props;
    this.setState({
      deletingOffer: true,
      deletingOfferError: false,
    });
    specialOffer(
      'PATCH',
      retailerDetails.code,
      {},
      {
        id: offer.id,
        offerType: 'SPEND_OFFER',
        delete: true,
      },
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          deletingOffer: false,
          deletingOfferError: false,
          deleteOffer: null,
        }, () => {
          this.handleOffer();
        });
      } else {
        throw new Error();
      }
    })
      .catch(() => {
        this.setState({
          deletingOffer: false,
          deletingOfferError: true,
        });
      });
  }

  handleSubmitSearchText = (value) => {
    const { activeTimeout } = this.state;
    if (activeTimeout !== -1) {
      clearTimeout(activeTimeout);
    }
    const timeout = setTimeout(() => {
      this.setState({
        activeTimeout: -1,
      }, () => {
        this.handleProductLoad(value);
      });
    }, 1000);
    this.setState({
      activeTimeout: timeout,
    });
  }

  handleProductLoad = (value) => {
    const { retailerDetails } = this.props;
    const { searchText, loadingProducts, productsFailure } = this.state;

    if (value !== searchText || productsFailure) {
      if (loadingProducts) {
        this.source.cancel();
        this.source = Axios.CancelToken.source();
      }
      this.setState({
        loadingProducts: true,
        productsFailure: false,
      });
      storeProduct(
        'GET',
        null,
        null,
        {
          storeId: retailerDetails.code,
          searchText: value,
        },
        this.source.token,
      ).then((res) => {
        this.setState({
          loadingProducts: false,
          searchText: value,
          storeProducts: res.data,
        });
      }).catch(() => {
        this.setState({
          loadingProducts: false,
          productsFailure: true,
        });
      });
    }
  }

  handleInputOnChange = (event) => {
    if (event.target.id === 'productQty') {
      if (!validateRegex('integerValue', event.target.value)) {
        return;
      }
    }
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  handleImageOnChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('img', file);

    uploadImage(
      'post',
      formData,
    ).then((res) => {
      this.setState({
        offerImage: res.data.url,
      });
    }).catch(() => {});
  }

  handleRadioButtonChange = (event) => {
    const { name } = event.target;
    this.setState((state) => ({
      [name]: !state[name],
    }));
  }

  handleSearch = (view = '') => {
    this.setState({
      ...view === '' ? { offersSearchText: '' } : {},
      view,
      loading: true,
      error: false,
    }, () => {
      this.handleOffer();
    });
  }

  getTagBgColor = (status) => {
    switch (status) {
      case 'success':
        return 'bg-success';
      case 'error':
        return 'bg-danger';
      default:
        return 'bg-green';
    }
  }

  render() {
    const {
      offers, loading, error, addSpendOffer, addingProduct,
      searchText, storeProducts, selectedproduct,
      submittingSpendOffer, spendAmount, offerImage,
      submittingSpendOfferError, productQty,
      deleteOffer, deletingOffer, deletingOfferError,
      createStory, offersSearchText, view,
    } = this.state;

    const offerLists = (
      <Row>
        <Col
          xs={13}
          className="mx-auto"
        >
          { offers && ((offers.data).map((offer, index) => (
            <Card
              border="secondary"
              xs="auto"
              className="mt-1 mx-auto"
              key={offer.id}
            >
              {
                view === 'search' && (
                  <Row>
                    <Col xs="auto" className="ml-auto">
                      <Button
                        variant="link"
                        className={`${this.getTagBgColor(offer.creatingStatus)} text-white font-weight-bold`}
                        onClick={() => { this.copySpendOffer(index); }}
                        disabled={offer.creatingStatus && offer.creatingStatus !== ''}
                      >
                        {(() => {
                          let str = 'ADD OFFER';
                          if (offer.creatingStatus) {
                            switch (offer.creatingStatus) {
                              case 'loading':
                                str = (
                                  <Spinner
                                    size="sm"
                                    animation="border"
                                    variant="primary"
                                    className="text-white"
                                  />
                                );
                                break;
                              case 'success':
                                str = (
                                  <>
                                    <Svg svg="circleDone" fill="white" width={20} />
                                    <span className="pl-2">Added</span>
                                  </>
                                );
                                break;
                              case 'error':
                                str = 'Error while addding';
                                break;
                              default: break;
                            }
                          }
                          return str;
                        })()}
                      </Button>
                    </Col>
                  </Row>
                )
              }
              <Row
                className="m-1"
              >
                <Col
                  xs={3}
                  className="mt-3"
                >
                  <Card.Img
                    src={offer.image}
                  />
                </Col>
                <Col
                  xs={21}
                >
                  <Card.Body
                    className="text-dark"
                  >
                    <Row>
                      <Col
                        xs={24}
                      >
                        <h6
                          className="card-text mb-2 fs-02 font-weight-bold"
                        >
                          {offer.description}
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={8}
                      >
                        <h6
                          className="card-text pt-3 mb-2 fs-03"
                        >
                          <b>
                            &#8377;
                            {offer.spendAmount}
                          </b>
                        </h6>
                      </Col>
                      <Col
                        xs={8}
                        className="pt-3"
                      >
                        <div
                          className="custom-control custom-switch"
                        >
                          <input
                            id={`status-${offer.id}`}
                            type="checkbox"
                            className="custom-control-input"
                            checked={offer.activeStatus}
                            onChange={() => {
                              this.handleChangeOfferStatus(!offer.activeStatus, offer);
                            }}
                            disabled={view === 'search' || !this.canIEdit}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`status-${offer.id}`}
                          >
                            Status
                          </label>
                        </div>
                      </Col>
                      {
                        !offer.activeStatus
                          && view !== 'search'
                          && (
                            <Col
                              xs={8}
                              className="pt-3"
                            >
                              <Button
                                variant="outline-danger-dark"
                                className="p-1 d-flex align-items-center fs-01"
                                onClick={() => {
                                  this.setState({
                                    deleteOffer: offer,
                                  });
                                }}
                                disabled={!this.canIEdit}
                              >
                                <Svg
                                  svg="trash"
                                  width={16}
                                  height={16}
                                />
                                  &nbsp;
                                <b>
                                  DELETE
                                </b>
                              </Button>
                            </Col>
                          )
                      }
                    </Row>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          ))) }
        </Col>
      </Row>
    );

    if (loading) {
      return (
        <div
          className="h-100 pt-3 d-flex align-items-center justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!loading && error) {
      return (
        <div
          className="h-100 d-flex align-items-center justify-content-center"
        >
          <ErrorHandler
            retryLogic={() => {
              this.setState({
                loading: true,
                error: false,
              }, () => {
                this.handleOffer();
              });
            }}
          />
        </div>
      );
    }

    const addSpendOfferModalBody = () => {
      if (submittingSpendOfferError) {
        return (
          <ErrorHandler
            retryLogic={() => {
              this.addSpendOffer();
            }}
          />
        );
      }

      return (
        <Container>
          <Row>
            {
              submittingSpendOffer
                ? (
                  <Col>
                    <div
                      className="w-100 pt-3 d-flex align-items-center justify-content-center"
                    >
                      <Spinner
                        animation="border"
                        variant="primary"
                      />
                    </div>
                  </Col>
                ) : (
                  <Col
                    className="mx-3 pt-3"
                  >
                    <Form
                      onSubmit={(e) => {
                        this.addSpendOffer(e);
                      }}
                    >
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          Free Gift :
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <input
                            type="checkbox"
                            checked={!!selectedproduct}
                            className="opacity-0"
                            required
                            readOnly
                          />
                          <Button
                            variant=""
                            className={`pl-0 ${selectedproduct !== null ? '' : 'text-primary font-weight-bold'}`}
                            onClick={() => {
                              this.setState({
                                addingProduct: 0,
                              });
                            }}
                          >
                            {
                              selectedproduct !== null
                                ? selectedproduct.displayName : '+ CHOOSE PRODUCT'
                            }
                          </Button>
                          <div
                            className="text-medium"
                          >
                            The customer will get this product free of cost
                            if they spend the specified amount.
                          </div>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          Qty:
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <input
                            value={productQty}
                            id="productQty"
                            onChange={this.handleInputOnChange}
                            required
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          Spend Amount (&#x20B9;):
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          <Form.Control
                            type="number"
                            value={spendAmount}
                            id="spendAmount"
                            onChange={this.handleInputOnChange}
                            autoComplete="off"
                            required
                          />
                          <div
                            className="mt-1 text-medium"
                          >
                            Minimum shopping amount for the offer
                          </div>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          Image:
                        </Form.Label>
                        <Col
                          sm={12}
                        >
                          {
                            offerImage ? (
                              <>
                                <img
                                  src={offerImage}
                                  alt="offer"
                                  width="100px"
                                />
                                <Button
                                  variant="outline-primary"
                                  className="ml-1"
                                  onClick={() => {
                                    this.setState({
                                      offerImage: '',
                                    });
                                  }}
                                >
                                  Change
                                </Button>
                              </>
                            ) : (
                              <input
                                type="file"
                                onChange={this.handleImageOnChange}
                              />
                            )
                          }
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          Create Story:
                        </Form.Label>
                        <Col
                          xs={4}
                          className="px-3"
                        >
                          <Form.Check
                            inline
                            label="Yes"
                            name="createStory"
                            type="radio"
                            checked={createStory}
                            onChange={this.handleRadioButtonChange}
                          />
                        </Col>
                        <Col
                          xs={4}
                          className="px-3"
                        >
                          <Form.Check
                            inline
                            label="No"
                            name="createStory"
                            type="radio"
                            checked={!createStory}
                            onChange={this.handleRadioButtonChange}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm={6} />
                        <Col sm={6}>
                          <Button
                            type="submit"
                            variant="outline-primary"
                            disabled={
                              Number(productQty) < 1
                            }
                          >
                            SUBMIT
                          </Button>
                        </Col>
                      </Form.Group>

                    </Form>
                  </Col>
                )
            }
          </Row>
        </Container>
      );
    };

    const searchProductHeaders = [
      {
        key: 'select',
        displayText: 'Select',
        renderer: (data) => (
          <Form.Check
            type="radio"
            id="same"
            onChange={() => {
              this.setState(
                {
                  searchText: '',
                  storeProducts: null,
                  addingProduct: null,
                  selectedproduct: data,
                },
                () => {
                  const { selectedproduct: product } = this.state;
                  if (product) {
                    this.setState({
                      offerImage: product.imageUrl,
                    });
                  }
                },
              );
            }}
          />
        ),
      },
      {
        key: 'id',
        displayText: 'Id',
      },
      {
        key: 'displayName',
        displayText: 'Product Name',
      },
      {
        key: 'sellingPrice',
        displayText: 'Price',
      },
      {
        key: 'unit',
        displayText: 'Qty.',
        renderer: (data) => (
          <span>
            {data.unit}
            {data.uom}
          </span>
        ),
      },
      {
        key: 'imageUrl',
        displayText: 'Image',
        renderer: (data) => (
          <img alt="" src={data.imageUrl} width="50px" height="50px" />
        ),
      },
    ];

    const addProductModalBody = () => {
      const { loadingProducts, productsFailure } = this.state;
      return (
        <Container>
          <Row>
            <Col
              className="px-0"
            >
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text
                    className="rounded-0"
                  >
                    <Svg
                      svg="search"
                      width="1rem"
                      fill={Constant.Color.DARK}
                    />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="search"
                  placeholder="search"
                  autoFocus
                  className="fs-1 rounded-0"
                  onChange={(e) => {
                    this.handleSubmitSearchText(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col
              xs="auto"
              className="px-0"
            >
              <Button
                className="rounded-0"
                onClick={() => {
                  this.setState({
                    searchText: '',
                    addingProduct: null,
                    storeProducts: null,
                  });
                }}
              >
                <Svg
                  svg="close"
                  width="1rem"
                  fill={Constant.Color.WHITE}
                />
              </Button>
            </Col>
          </Row>
          <Row
            fluid="true"
          >
            {
              // eslint-disable-next-line no-nested-ternary
              loadingProducts ? (
                <div
                  className="w-100 pt-3 d-flex align-items-center justify-content-center"
                >
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                </div>
              // eslint-disable-next-line no-nested-ternary
              ) : productsFailure ? (
                <div
                  className="h-100 d-flex align-items-center
                      justify-content-center"
                >
                  <ErrorHandler
                    retryLogic={() => {
                      this.handleProductLoad(searchText);
                    }}
                  />
                </div>
              ) : storeProducts !== null ? (
                <CustomTable
                  headers={searchProductHeaders}
                  content={storeProducts.results}
                  keyField="id"
                  totalItems={storeProducts.count}
                  isPaginated={false}
                />
              ) : ''
            }
          </Row>
        </Container>
      );
    };

    return (
      <Container
        fluid
        className="bg-white"
      >
        <CustomModal
          body={addSpendOfferModalBody()}
          closeButton
          size="lg"
          show={addSpendOffer}
          title="Add Free Gift offer"
          onHide={() => {
            this.setState({
              addSpendOffer: false,
              selectedproduct: null,
              productQty: 1,
              spendAmount: '',
              offerImage: '',
              submittingSpendOfferError: false,
              createStory: true,
            });
          }}
        />
        <CustomModal
          body={addProductModalBody()}
          show={addingProduct !== null}
          size="lg"
          onHide={() => {
            this.setState({
              searchText: '',
              storeProducts: null,
              addingProduct: null,
            });
          }}
        />
        <CustomModal
          show={!!deleteOffer}
          autoSize
          body={(
            <div
              className="p-3"
            >
              <b>Are you sure?</b>
              <div
                className="pt-1"
              >
                You should delete an offer only if you are sure that you don’t want to use it again.
              </div>
            </div>
          )}
          onHide={() => {
            if (!deletingOffer) {
              this.setState({
                deleteOffer: null,
                deletingOffer: false,
                deletingOfferError: false,
              });
            }
          }}
          footer={(
            <>
              {
                deletingOfferError && (
                  <b
                    className="text-danger"
                  >
                    Error: Please try again!!
                  </b>
                )
              }
              <Button
                variant="link"
                className="text-black"
                disabled={deletingOffer}
                onClick={() => {
                  this.setState({
                    deleteOffer: null,
                    deletingOffer: false,
                    deletingOfferError: false,
                  });
                }}
              >
                <b>GO BACK</b>
              </Button>
              {
                deletingOffer ? (
                  <Spinner
                    variant="danger-dark"
                    animation="border"
                    size="sm"
                  />
                ) : (
                  <Button
                    variant="link"
                    className="text-danger-dark"
                    onClick={() => {
                      this.handleDeleteOffer(deleteOffer);
                    }}
                  >
                    <b>DELETE OFFER</b>
                  </Button>
                )
              }
            </>
          )}
        />
        <Row>
          <Col
            xs="auto"
            className={`p-3 ${this.canIEdit ? '' : 'd-none'}`}
          >
            <Button
              variant="outline-primary"
              onClick={() => {
                this.setState({
                  addSpendOffer: true,
                });
              }}
            >
              ADD FREE GIFT OFFER
            </Button>
          </Col>

          <Col
            xs="auto"
            className="p-3 d-flex"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="search"
                placeholder="Search Offers"
                className="fs-1 rounded-0"
                id="offersSearchText"
                value={offersSearchText}
                onChange={this.handleInputOnChange}
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.handleSearch('search');
                  }
                }}
              />
            </InputGroup>
            <Button
              onClick={() => this.handleSearch()}
              variant="primary"
              disabled={view !== 'search'}
            >
              X
            </Button>
          </Col>
        </Row>
        <Row>
          {offers && (
            <Col
              xs={24}
              className="pb-3"
            >
              {offerLists}
            </Col>
          )}
        </Row>
        <Row>
          <Col
            xs={24}
            className="text-center"
          >
            {offers && offers.data.length === 0 && (
            <h6
              className="p-3 text-danger"
            >
              Not Available any Free Gift offer
            </h6>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

FreeGift.propTypes = {
  retailerDetails: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default FreeGift;
