import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import {
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal, CustomTable, ErrorHandler } from '../../component/common';
import { storeCommentLogs } from '../../assets/api/axios';
import { dateString } from '../../utilities/Utils';

function ViewCommentsHistory(props) {
  const { show, onHide } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [comments, setComments] = useState(null);

  const handleLoad = () => {
    const { storeCode } = props;
    setLoading(true);
    setError(false);
    storeCommentLogs(
      'GET',
      {
        page: 1,
        limit: 10,
        storeCode: Number(storeCode),
        format: 'table',
      },
    ).then((res) => {
      setLoading(false);
      setComments(res.data.data);
    }).catch(() => {
      setLoading(false);
      setError(true);
    });
  };

  useEffect(() => {
    handleLoad();
    // eslint-disable-next-line
  }, []);

  const body = (
    <>
      {(() => {
        let showcase = null;
        if (!comments && loading) {
          showcase = (
            <div
              className="h-100 d-flex align-items-center
              justify-content-center p-4"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </div>
          );
        } else if (!comments && !loading && error) {
          showcase = (
            <div
              className="h-100 d-flex align-items-center
              justify-content-center p-4"
            >
              <ErrorHandler
                retryLogic={() => {
                  handleLoad();
                }}
              />
            </div>
          );
        } else if (comments) {
          showcase = (
            <Container className="p-4">
              <Row>
                <Col>
                  <Row>
                    <Col
                      className="px-0 overflow-y minh-775p"
                    >
                      <CustomTable
                        headers={[
                          {
                            key: 'comment',
                            displayText: 'Comment',
                          },
                          {
                            key: 'addedBy',
                            displayText: 'Added By',
                          },
                          {
                            key: 'addedFrom',
                            displayText: 'Added From',
                          },
                          {
                            key: 'createdAt',
                            displayText: 'TimeStamp',
                            renderer: (data) => dateString(data.createdAt) || '--',
                          },
                        ]}
                        content={comments.results}
                        keyField="commentId"
                        totalItems={comments.count}
                        isPaginated={false}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        }
        return showcase;
      })()}
    </>
  );

  return (
    <CustomModal
      show={show}
      onHide={onHide}
      title="Comment History"
      size="lg"
      closeButton
      body={body}
    />
  );
}

ViewCommentsHistory.propTypes = {
  show: PropType.bool.isRequired,
  onHide: PropType.func.isRequired,
  storeCode: PropType.string.isRequired,
};

export default ViewCommentsHistory;
