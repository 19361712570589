import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import QueryString from 'query-string';
import {
  Header, Sidebar, Backdrop, SideOverlay,
} from './layouts/wrapper';
import {
  setToken, setUserName, setUserNumber,
  setUserSession, removeToken, removeUserName,
  removeUserNumber, removeUserSession, removeUserGroup,
  removeUserPermission, removeUser, setUserPermissions,
  getUser, setUser, getUserSession, removeFCMToken,
  removeLeadsSchema, getLastLoggedInAtEpoch, setLastLoggedInAtEpoch,
  removeLoginStep, getLoginStep,
} from './utilities/Storage';
import { Utils } from './utilities';
import { userLogin } from './assets/api/axios';
import Token from './assets/auth/Token';
import { unsubscribeFCM } from './integrations/firebase/Messaging';

class Wrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      isIframeView: false,
      loggingOut: false,
    };
    this.intervalId = 0;
  }

  componentDidMount() {
    const { history, location, updateUserAuthorization } = this.props;
    const params = QueryString.parse(location.search);
    const loginStep = getLoginStep();
    this.setState({
      isIframeView: params.isIframeView || false,
    });
    if (
      params.token
      && params.token === 'e5ad88aa-d0c0-4fcb-952c-22a661bb865b'
      && params.userSession
      && params.userName
      && params.userName !== ''
      && params.userName !== 'undefined'
      && params.userName !== 'null'
      && params.userPermissions
      && loginStep === 'google_authenticated'
    ) {
      setToken(params.token);
      setUserName(params.userName);
      setUserNumber(params.userNumber);
      setUserSession(params.userSession);
      setUserPermissions(JSON.parse(params.userPermissions));
      delete params.token;
      delete params.userName;
      delete params.userNumber;
      delete params.userSession;
      delete params.userPermissions;
      const queryString = QueryString.stringify(params);
      updateUserAuthorization();
      history.push(`${location.pathname}${queryString ? `?${queryString}` : ''}`);
    } else if (!(Utils.isAuthenticated() && loginStep === 'google_authenticated')) {
      delete params.token;
      delete params.userName;
      delete params.userNumber;
      delete params.userSession;
      delete params.userPermissions;
      let newRequest = '/';
      if (location.pathname !== '/signin') {
        const query = QueryString.stringify(params);
        newRequest = params.request || `${location.pathname}${query ? `?${query}` : ''}`;
      }
      history.push(`/signin?request=${newRequest}`);
    }
    this.logoutForcefully();
    this.udateUerInfoAfterInterval();
  }

  logoutForcefully = () => {
    // logging out forcefully who has last logged in before 06/09/2022 4:10:00AM
    const loggedInAt = getLastLoggedInAtEpoch();
    if (Utils.isAuthenticated() && Number(loggedInAt) < (1662417600 * 1000)) {
      this.onLogout();
    }
  }

  componentWillUnmount = () => {
    if (this.intervalId) {
      this.intervalId = clearInterval(this.intervalId);
    }
  }

  toggleSidebar = () => {
    const { show } = this.state;
    this.setState({
      show: !show,
    });
  }

  udateUerInfoAfterInterval = () => {
    if (Utils.isAuthenticated()) {
      const user = getUser();
      const { refreshTokenInSec } = user || {};
      if (
        !this.intervalId
        && refreshTokenInSec
        && refreshTokenInSec > 0
      ) {
        this.intervalId = setInterval(() => {
          this.updateUserDetailsInLocalStorage();
        }, (refreshTokenInSec) * 1000);
      }
    }
  }

  updateUserDetailsInLocalStorage = () => {
    const { updateUserAuthorization } = this.props;
    const prevSessionToken = getUserSession();
    userLogin(
      'get',
      null,
    )
      .then((res) => {
        const {
          username: user, phoneNumber, sessionToken, permissionsSet,
          isValid,
        } = res.data.data;
        if (isValid) {
          if (prevSessionToken && prevSessionToken !== sessionToken) {
            setToken(Token);
            setUserName(user);
            setUserNumber(phoneNumber);
            setUserSession(sessionToken);
            setUserPermissions(permissionsSet);
            setUser(res.data);
            setLastLoggedInAtEpoch(new Date().getTime());
            updateUserAuthorization();
          }
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        this.onLogout();
      });
  }

  onLogout = () => {
    const { history, updateUserAuthorization } = this.props;
    this.setState({
      loggingOut: true,
    });
    removeToken();
    removeUserName();
    removeUserNumber();
    removeUserSession();
    removeUserGroup();
    removeUserPermission();
    removeLoginStep();
    removeUser();
    updateUserAuthorization();
    removeFCMToken();
    unsubscribeFCM();
    removeLeadsSchema();
    history.push('/signin?request=/');
    this.setState({
      loggingOut: false,
    });
    this.intervalId = clearInterval(this.intervalId);
  }

  render() {
    const { Component, location } = this.props;
    const { show, isIframeView, loggingOut } = this.state;

    return (
      <div
        className="h-100"
      >
        <SideOverlay
          {...this.props}
          show={show}
          ChildComponent={Sidebar}
          toggleSideOverlay={this.toggleSidebar}
        />
        <Backdrop
          show={show}
          onFade={this.toggleSidebar}
        />
        <Container
          fluid
          className="px-0 h-100"
        >
          <Row
            className="mx-0 h-100"
          >
            <Col
              className="h-50p px-0 fixed"
            >
              {location.pathname === '/signin'
              || isIframeView
                ? ''
                : (
                  <Header
                    toggleSidebar={this.toggleSidebar}
                    onLogout={this.onLogout}
                    loggingOut={loggingOut}
                  />
                )}
            </Col>
            <Col
              className={`px-0 ${isIframeView
                ? 'h-100' : 'main-section'}`}
              xs={24}
            >
              <Component
                {...this.props}
                onLogout={this.onLogout}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Wrapper.propTypes = {
  Component: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  updateUserAuthorization: PropTypes.func.isRequired,
};

export default Wrapper;
