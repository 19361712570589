import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import { Svg } from '../../../component/common';
import SuccessfulModal from './SuccessfulModal';
import { deliveryHexes, deliverySettings } from '../../../assets/api/axios';

function DeliveryInfo(props) {
  const {
    storeCode, modalView, handleViewChange,
    selectedHexes, deliveryRadii, validateDeliveryRadius,
  } = props;
  const [showWarning, setShowWarning] = useState(false);
  const [submittingState, setSubmittingState] = useState('');

  const handleUpdateHexes = () => {
    if (selectedHexes.length < 8) {
      setShowWarning(true);
      return;
    }
    setSubmittingState('loading');
    setShowWarning(false);
    deliveryHexes(
      'POST',
      storeCode,
      { hexIds: selectedHexes },
    ).then(() => {
      setSubmittingState('success');
      handleViewChange('VIEW');
    }).catch(() => {
      setSubmittingState('error');
    });
  };

  const handleUpdateRadius = () => {
    if (!validateDeliveryRadius()) {
      return;
    }
    const newDeliveryRadii = deliveryRadii.map((radii) => (
      {
        ...radii,
        radius: parseFloat(radii.radius) * 1000,
        amount: parseInt(radii.amount, 10),
      }
    ));
    setSubmittingState('loading');
    deliverySettings(
      'PATCH',
      storeCode,
      {
        deliveryRadii: newDeliveryRadii,
      },
    ).then(() => {
      handleUpdateHexes();
    }).catch(() => {
      setSubmittingState('error');
    });
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <>
      {
        !!submittingState && (
          <SuccessfulModal
            show={!!submittingState}
            onHide={() => {
              setSubmittingState('');
              refreshPage();
            }}
            submittingState={submittingState}
            retry={handleUpdateRadius}
          />
        )
      }
      <Row
        className="border border-radius-16"
      >
        <Col>
          <Row className="p-3">
            <Col>
              <Row>
                <b>KEY</b>
              </Row>
              <Row className="d-flex align-items-center mt-2">
                <Svg
                  svg="delivering"
                  width="12"
                  height="15"
                />
                &nbsp;&nbsp;
                Delivering
              </Row>
              <Row className="d-flex align-items-center mt-2">
                <Svg
                  svg="nonDelivering"
                  width="12"
                  height="15"
                />
                &nbsp;&nbsp;
                Not Delivering
              </Row>
            </Col>
          </Row>
          <Row
            className="px-3 py-2 bg-light d-flex"
            style={{
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
            }}
          >
            {
              modalView === 'VIEW' ? (
                <Col>
                  <Button
                    variant="green-dark"
                    className=" fs-01 border-radius-8"
                    block
                    onClick={() => { handleViewChange('EDIT'); }}
                  >
                    <b>Edit</b>
                  </Button>
                </Col>
              ) : (
                <Col>
                  <Button
                    variant="white"
                    className="fs-01 font-weight-bold border-radius-8"
                    block
                    onClick={() => {
                      handleViewChange('VIEW');
                      setShowWarning(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              )
            }
          </Row>
        </Col>
      </Row>
      <Row
        className="mt-3 px-3 py-2 bg-white d-flex border border-radius-16"
      >
        <Col>
          <Button
            variant="primary"
            className="fs-01 font-weight-bold border-radius-8"
            block
            onClick={() => {
              handleUpdateRadius();
            }}
          >
            Save Changes
          </Button>
        </Col>
      </Row>
      {
        showWarning && (
          <Row className="mt-3 d-flex align-items-center justify-content-center bg-danger">
            <div>
              <span className="text-white">Cannot select less than 8 clusters</span>
            </div>
            <Button
              variant="link"
              onClick={() => { setShowWarning(false); }}
            >
              <b className="text-white">OK</b>
            </Button>
          </Row>
        )
      }
    </>
  );
}

DeliveryInfo.propTypes = {
  storeCode: PropTypes.string.isRequired,
  modalView: PropTypes.string.isRequired,
  handleViewChange: PropTypes.func.isRequired,
  selectedHexes: PropTypes.arrayOf(PropTypes.string).isRequired,
  deliveryRadii: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  validateDeliveryRadius: PropTypes.func.isRequired,
};

export default DeliveryInfo;
