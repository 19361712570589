import React, { Component } from 'react';
import {
  Container, Row, Col, Spinner, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import { retailerPayment } from '../../assets/api/axios';
import { dateString } from '../../utilities/Utils';
import {
  CustomTable,
} from '../../component/common';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    ...param,
    l,
    p,
  });
};

class RetailerPayments extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    this.state = {
      payments: null,
      rowsPerPage: param.l,
      page: param.p,
      loading: true,
      error: false,
      param,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  handleLoad = (data = {}) => {
    const { history, retailerDetails } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const { l, p } = newParam;
    retailerPayment(
      'GET',
      retailerDetails.code,
      {
        page: p,
        limit: l,
      },
    ).then((res) => {
      this.setState({
        payments: res.data,
        loading: false,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  render() {
    const {
      payments, loading, error, rowsPerPage,
      page, param,
    } = this.state;

    const headers = [
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => (
          dateString(data.createdOn)
        ),
      },
      {
        key: 'rz_order_id',
        displayText: 'RZ Order Id',
      },
      {
        key: 'rz_payment_id',
        displayText: 'RZ Payment Id',
      },
      {
        key: 'rz_transfer_id',
        displayText: 'RZ Transfer Id',
      },
      {
        key: 'amount',
        displayText: 'Amount',
      },
      {
        key: 'status',
        displayText: 'Status',
      },
      {
        key: 'reconciled',
        displayText: 'Reconciled',
      },
    ];

    if (loading || error) {
      return (
        <div
          className="pt-3 text-center"
        >
          {loading ? (
            <Spinner
              animation="border"
              variant="primary"
            />
          ) : (
            <>
              <span
                className="text-danger"
              >
                Something Went Wrong
              </span>
              <div>
                <Button
                  variant="primary"
                  onClick={() => this.retry()}
                >
                  Retry
                </Button>
              </div>
            </>
          )}
        </div>
      );
    }

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        <Row>
          <Col
            xs={24}
            className="px-0"
          >
            <CustomTable
              headers={headers}
              content={payments.data}
              keyField="rz_order_id"
              l={param.l}
              p={param.p}
              rowsPerPage={rowsPerPage}
              page={page}
              totalItems={payments.count}
              hasPrev={payments.prev}
              hasNext={payments.next}
              onNext={this.onNext}
              onPrev={this.onPrev}
              onSubmitPage={this.onSubmitPage}
              onSubmitRowsPerPage={this.onSubmitRowsPerPage}
              updateRowsPageInput={this.handleRowsPageInput}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

RetailerPayments.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  retailerDetails: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
};

export default RetailerPayments;
