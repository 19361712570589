/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Spinner, Card, Button, Form, InputGroup,
} from 'react-bootstrap';
import Axios from 'axios';
import {
  CustomModal, CustomTable, ErrorHandler, Svg,
} from '../../component/common';
import Constant from '../../utilities/Constant';
import { unitsMap } from '../../utilities/Utils';
import {
  productVariant, specialOffer, storeProduct, uploadImage,
} from '../../assets/api/axios';
import { validateRegex } from '../../utilities/FormValidations';
import Permission from '../../access&permissions/permission';

const getOfferName = (product1Qty, product2Qty, selectedProduct1, selectedProduct2) => (
  `${
    Number(product1Qty) > 1
    || Number(product2Qty) > 1
      ? `${product1Qty} ` : ''
  }${
    selectedProduct1 ? selectedProduct1.displayName : ''
  }${
    selectedProduct1?.uom && selectedProduct1?.unit
      ? ` (${selectedProduct1.unit}${unitsMap[selectedProduct1.uom] || selectedProduct1.uom})`
      : ''
  } + ${
    Number(product1Qty) > 1
    || Number(product2Qty) > 1
      ? `${product2Qty} ` : ''
  }${
    selectedProduct2 ? selectedProduct2.displayName : ''
  }${
    selectedProduct2?.uom && selectedProduct2?.unit
      ? ` (${selectedProduct2.unit}${unitsMap[selectedProduct2.uom] || selectedProduct2.uom})`
      : ''
  }`
);

class BogoOffer extends React.Component {
  constructor(props) {
    super(props);
    const { userPermission } = props;
    this.canIEdit = userPermission
      && (userPermission.includes(Permission.RETAILER_WRITE)
      || userPermission.includes(Permission.RETAILER_PERMISSION_ON_SPECIAL_OFFER_PAGE));
    this.state = {
      offers: null,
      loading: true,
      error: false,
      addBogoOffer: false,
      submittingBogoOffer: false,
      submittingBogoOfferError: false,
      addingProduct: null,
      storeProducts: null,
      searchText: '',
      activeTimeout: -1,
      loadingProducts: false,
      productsFailure: false,
      selectedProduct1: null,
      selectedProduct2: null,
      product1Qty: 1,
      product2Qty: 1,
      offerPrice: '',
      offerImage: '',
      deleteOffer: null,
      deletingOffer: false,
      deletingOfferError: false,
      updatedBogoName: '',
      createStory: true,
      offersSearchText: '',
      view: '',
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleOffer();
  }

  handleOffer = () => {
    const { retailerDetails } = this.props;
    const { view, offersSearchText } = this.state;
    const offerTypes = 'COMBO_OFFER';

    const payload = {
      offerType: offerTypes,
      page: 1,
      limit: 30,
    };
    if (view === 'search') {
      payload.searchText = offersSearchText;
    }

    specialOffer(
      'GET',
      retailerDetails.code,
      payload,
    ).then((res) => {
      this.setState({
        offers: res.data,
        loading: false,
        error: false,
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad();
      });
    };
  }

  copyBogoOffer = (index) => {
    const { offers } = this.state;
    const { retailerDetails } = this.props;
    const offersList = offers.data;
    offersList[index].creatingStatus = 'loading';
    const offer = offersList[index];
    this.setState({
      offers: {
        ...offers,
        data: offersList,
      },
    });
    specialOffer(
      'POST',
      retailerDetails.code,
      {},
      {
        category: offer.categories[0][0],
        image:
          offer.image
          || offer.productImage1
          || offer.productImage2
          || '',
        name: offer.name,
        offerType: 'COMBO_OFFER',
        description: offer.description,
        sellingPrice: offer.sellingPrice,
        unit: '1',
        uom: 'Units',
        libraryProductId1: offer.libraryProductId1,
        libraryProductId2: offer.libraryProductId2,
        bogoOfferId: offer.id,
        createStory: true,
      },
    ).then(() => {
      offersList[index].creatingStatus = 'success';
      this.setState({
        offers: {
          ...offers,
          data: offersList,
        },
      });
    }).catch(() => {
      offersList[index].creatingStatus = 'error';
      this.setState({
        offers: {
          ...offers,
          data: offersList,
        },
      });
    });
  }

  addBogoOffer = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { retailerDetails } = this.props;
    const {
      offerImage, selectedProduct1, selectedProduct2,
      offerPrice, product1Qty, product2Qty, updatedBogoName,
      createStory,
    } = this.state;

    const offerName = getOfferName(product1Qty, product2Qty, selectedProduct1, selectedProduct2);

    this.setState({
      submittingBogoOffer: true,
    });
    specialOffer(
      'POST',
      retailerDetails.code,
      {},
      {
        category: selectedProduct1.category || selectedProduct2.category,
        image: offerImage,
        name: updatedBogoName || offerName,
        offerType: 'COMBO_OFFER',
        description: offerName,
        sellingPrice: offerPrice,
        unit: '1',
        uom: 'Units',
        ...selectedProduct1.isLibraryProduct
          ? { libraryProductId1: selectedProduct1.id }
          : { retailerProductId1: selectedProduct1.id },
        qty1: product1Qty,
        ...selectedProduct2.isLibraryProduct
          ? { libraryProductId2: selectedProduct2.id }
          : { retailerProductId2: selectedProduct2.id },
        qty2: product2Qty,
        createStory,
      },
    ).then(() => {
      this.setState({
        addBogoOffer: false,
        submittingBogoOffer: false,
        selectedProduct1: null,
        selectedProduct2: null,
        product1Qty: 1,
        product2Qty: 1,
        offerPrice: '',
        offerImage: '',
        updatedBogoName: '',
        createStory: true,
      }, () => {
        this.handleOffer();
      });
    }).catch(() => {
      this.setState({
        submittingBogoOffer: false,
        submittingBogoOfferError: true,
      });
      return false;
    });
  }

  handleChangeOfferStatus = (inStock, offer) => {
    const { retailerDetails } = this.props;
    const { offers } = this.state;
    const index = offers.data.findIndex((value) => value.id === offer.id);
    specialOffer(
      'PATCH',
      retailerDetails.code,
      {},
      {
        id: offer.id,
        offerType: 'COMBO_OFFER',
        activeStatus: inStock,
      },
    ).then((res) => {
      if (res.status === 200) {
        this.setState((state) => ({
          offers: {
            ...state.offers,
            data: Object.assign(
              [...state.offers.data],
              {
                [index]: {
                  ...state.offers.data[index],
                  inStock,
                },
              },
            ),
          },
        }));
      } else {
        throw new Error();
      }
    })
      .catch(() => {});
  }

  handleDeleteOffer = (offer) => {
    const { retailerDetails } = this.props;
    this.setState({
      deletingOffer: true,
      deletingOfferError: false,
    });
    specialOffer(
      'PATCH',
      retailerDetails.code,
      {},
      {
        id: offer.id,
        offerType: 'COMBO_OFFER',
        delete: true,
      },
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          deletingOffer: false,
          deletingOfferError: false,
          deleteOffer: null,
        }, () => {
          this.handleOffer();
        });
      } else {
        throw new Error();
      }
    })
      .catch(() => {
        this.setState({
          deletingOffer: false,
          deletingOfferError: true,
        });
      });
  }

  handleSubmitSearchText = (value) => {
    const { activeTimeout } = this.state;
    if (activeTimeout !== -1) {
      clearTimeout(activeTimeout);
    }
    const timeout = setTimeout(() => {
      this.setState({
        activeTimeout: -1,
      }, () => {
        this.handleProductLoad(value);
      });
    }, 350);
    this.setState({
      activeTimeout: timeout,
    });
  }

  handleProductLoad = (value) => {
    const { retailerDetails } = this.props;
    const { searchText, loadingProducts, productsFailure } = this.state;

    if (value !== searchText || productsFailure) {
      if (loadingProducts) {
        this.source.cancel();
        this.source = Axios.CancelToken.source();
      }
      this.setState({
        loadingProducts: true,
        productsFailure: false,
      });
      storeProduct(
        'GET',
        null,
        null,
        {
          storeId: retailerDetails.code,
          searchText: value,
        },
        this.source.token,
      ).then((res) => {
        this.setState({
          loadingProducts: false,
          searchText: value,
          storeProducts: res.data,
        });
      }).catch(() => {
        this.setState({
          loadingProducts: false,
          productsFailure: true,
        });
      });
    }
  }

  handleInputOnChange = (event) => {
    if (event.target.id === 'product1Qty' || event.target.id === 'product2Qty') {
      if (!validateRegex('integerValue', event.target.value)) {
        return;
      }
    }
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  handleImageOnChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('img', file);

    uploadImage(
      'post',
      formData,
    ).then((res) => {
      this.setState({
        offerImage: res.data.url,
      });
    }).catch(() => {});
  }

  handleRadioButtonChange = (event) => {
    const { name } = event.target;
    this.setState((state) => ({
      [name]: !state[name],
    }));
  }

  handleSearch = (view = '') => {
    this.setState({
      ...view === '' ? { offersSearchText: '' } : {},
      view,
      loading: true,
      error: false,
    }, () => {
      this.handleOffer();
    });
  }

  getTagBgColor = (status) => {
    switch (status) {
      case 'success':
        return 'bg-success';
      case 'error':
        return 'bg-danger';
      default:
        return 'bg-green';
    }
  }

  render() {
    const {
      offers, loading, error, addBogoOffer, addingProduct,
      searchText, storeProducts, selectedProduct1, selectedProduct2,
      submittingBogoOffer, offerPrice, offerImage,
      submittingBogoOfferError, product1Qty, product2Qty,
      deleteOffer, deletingOffer, deletingOfferError,
      updatedBogoName, createStory, offersSearchText,
      view,
    } = this.state;

    const offerName = getOfferName(product1Qty, product2Qty, selectedProduct1, selectedProduct2);

    const offerLists = (
      <Row>
        <Col
          xs={13}
          className="mx-auto"
        >
          {
            offers
            && offers.data.map(
              (offer, index) => (
                <Card
                  border="secondary"
                  xs="auto"
                  className="mt-1 mx-auto"
                  key={offer.id}
                >
                  {
                    view === 'search' && (
                      <Row className="ml-auto">
                        <Col xs="auto">
                          <Button
                            variant="link"
                            className={`${this.getTagBgColor(offer.creatingStatus)} text-white font-weight-bold`}
                            onClick={() => { this.copyBogoOffer(index); }}
                            disabled={offer.creatingStatus && offer.creatingStatus !== ''}
                          >
                            {(() => {
                              let str = 'ADD OFFER';
                              if (offer.creatingStatus) {
                                switch (offer.creatingStatus) {
                                  case 'loading':
                                    str = (
                                      <Spinner
                                        size="sm"
                                        animation="border"
                                        variant="primary"
                                        className="text-white"
                                      />
                                    );
                                    break;
                                  case 'success':
                                    str = (
                                      <>
                                        <Svg svg="circleDone" fill="white" width={20} />
                                        <span className="pl-2">Added</span>
                                      </>
                                    );
                                    break;
                                  case 'error':
                                    str = 'Error while addding';
                                    break;
                                  default: break;
                                }
                              }
                              return str;
                            })()}
                          </Button>
                        </Col>
                      </Row>
                    )
                  }
                  <Row
                    className="m-1"
                  >
                    <Col
                      xs={3}
                      className="mt-3 pr-0 d-flex justify-content-center"
                    >
                      <Card.Img
                        src={
                          offer.productImage1
                          || 'https://storage.googleapis.com/homedelivery-product-images/Placeholder(1).png'
                        }
                        className="h-50p w-50p"
                      />
                    </Col>
                    <Col
                      xs={1}
                      className="h-50p mt-3 px-0 d-flex align-items-center justify-content-center"
                    >
                      <span className="font-weight-bold">+</span>
                    </Col>
                    <Col
                      xs={3}
                      className="mt-3 pl-0 d-flex justify-content-center"
                    >
                      <Card.Img
                        src={
                          offer.productImage2
                          || 'https://storage.googleapis.com/homedelivery-product-images/Placeholder(1).png'
                        }
                        className="h-50p w-50p"
                      />
                    </Col>
                    <Col
                      xs={17}
                    >
                      <Card.Body
                        className="text-dark"
                      >
                        <Row>
                          <Col
                            xs={24}
                          >
                            <h6
                              className="card-text mb-2 fs-02 font-weight-bold"
                            >
                              { offer.description }
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            xs={8}
                            className="pt-3"
                          >
                            <span>
                              {`\u20B9 ${offer.sellingPrice}`}
                            </span>
                            <span
                              className={`${offer.mrp === -1
                                || offer.mrp
                                    === offer.sellingPrice
                                ? 'd-none' : 'm-0 pl-3 text-medium'}`}
                            >
                              {'\u20B9 '}
                              <del>
                                {offer.mrp}
                              </del>
                            </span>
                          </Col>
                          <Col
                            xs={8}
                            className="pt-3"
                          >
                            <div
                              className="custom-control custom-switch"
                            >
                              <input
                                id={`status-${offer.id}`}
                                type="checkbox"
                                className="custom-control-input"
                                checked={offer.inStock}
                                onChange={() => {
                                  this.handleChangeOfferStatus(
                                    !offer.inStock,
                                    offer,
                                  );
                                }}
                                disabled={view === 'search' || !this.canIEdit}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`status-${offer.id}`}
                              >
                                Status
                              </label>
                            </div>
                          </Col>
                          {
                            !offer.inStock
                              && view !== 'search'
                              && (
                                <Col
                                  xs={8}
                                  className="pt-3"
                                >
                                  <Button
                                    variant="outline-danger-dark"
                                    className="p-1 d-flex align-items-center fs-01"
                                    onClick={() => {
                                      this.setState({
                                        deleteOffer: offer,
                                      });
                                    }}
                                    disabled={!this.canIEdit}
                                  >
                                    <Svg
                                      svg="trash"
                                      width={16}
                                      height={16}
                                    />
                                      &nbsp;
                                    <b>
                                      DELETE
                                    </b>
                                  </Button>
                                </Col>
                              )
                          }
                        </Row>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              ),
            )
          }
        </Col>
      </Row>
    );

    if (loading) {
      return (
        <div
          className="h-100 pt-3 d-flex align-items-center justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!loading && error) {
      return (
        <div
          className="h-100 d-flex align-items-center justify-content-center"
        >
          <ErrorHandler
            retryLogic={() => {
              this.setState({
                loading: true,
                error: false,
              }, () => {
                this.handleOffer();
              });
            }}
          />
        </div>
      );
    }

    const addBogoOfferModalBody = () => {
      if (submittingBogoOfferError) {
        return (
          <div>
            <ErrorHandler
              retryLogic={() => {
                this.addBogoOffer();
              }}
            />
          </div>
        );
      }

      return (
        <Container>
          <Row>
            {
              submittingBogoOffer
                ? (
                  <Col>
                    <div
                      className="w-100 pt-3 d-flex align-items-center justify-content-center"
                    >
                      <Spinner
                        animation="border"
                        variant="primary"
                      />
                    </div>
                  </Col>
                ) : (
                  <Col
                    className="mx-3 pt-3"
                  >
                    <Form
                      onSubmit={(e) => {
                        this.addBogoOffer(e);
                      }}
                    >
                      <Row
                        className="pb-3"
                      >
                        <Col
                          xs={6}
                        >
                          Suggested Name:
                        </Col>
                        <Col
                          className="fs-0 text-info"
                        >
                          {offerName}
                        </Col>
                      </Row>
                      <Row
                        className="pb-3"
                      >
                        <Col
                          xs={6}
                        >
                          Update Name:
                        </Col>
                        <Col
                          xs={14}
                        >
                          <textarea
                            className="form-control shadow-sm"
                            type="text"
                            aria-multiline
                            rows="4"
                            value={updatedBogoName}
                            onChange={(event) => {
                              this.setState({
                                updatedBogoName: event.target.value,
                              });
                            }}
                          />
                          <div
                            className="text-danger"
                          >
                            <b>Note:&nbsp;</b>
                            Leave Text box blank if the suggested name is fine.
                          </div>
                        </Col>
                      </Row>
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          Product 1:
                        </Form.Label>
                        <Col
                          sm={14}
                        >
                          <input
                            type="checkbox"
                            checked={!!selectedProduct1}
                            className="opacity-0"
                            required
                            readOnly
                          />
                          <Button
                            variant=""
                            className={`pl-0 ${selectedProduct1 !== null ? '' : 'text-primary font-weight-bold'}`}
                            onClick={() => {
                              this.setState({
                                addingProduct: 0,
                              });
                            }}
                          >
                            {
                              selectedProduct1 !== null
                                ? selectedProduct1.displayName : '+ Add Product'
                            }
                          </Button>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          Qty:
                        </Form.Label>
                        <Col
                          sm={14}
                        >
                          <input
                            className="form-control"
                            value={product1Qty}
                            id="product1Qty"
                            onChange={this.handleInputOnChange}
                            autoComplete="off"
                            required
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          Product 2:
                        </Form.Label>
                        <Col
                          sm={14}
                        >
                          <input
                            type="checkbox"
                            checked={!!selectedProduct2}
                            className="opacity-0"
                            required
                            readOnly
                          />
                          <Button
                            variant=""
                            className={`pl-0 ${selectedProduct2 !== null ? '' : 'text-primary font-weight-bold'}`}
                            onClick={() => {
                              this.setState({
                                addingProduct: 1,
                              });
                            }}
                          >
                            {
                              selectedProduct2 !== null
                                ? selectedProduct2.displayName : '+ Add Product'
                            }
                          </Button>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          Qty:
                        </Form.Label>
                        <Col
                          sm={14}
                        >
                          <input
                            className="form-control"
                            value={product2Qty}
                            id="product2Qty"
                            onChange={this.handleInputOnChange}
                            autoComplete="off"
                            required
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          Offer Price:
                        </Form.Label>
                        <Col
                          sm={14}
                        >
                          <Form.Control
                            type="number"
                            value={offerPrice}
                            id="offerPrice"
                            onChange={this.handleInputOnChange}
                            autoComplete="off"
                            required
                          />
                          {
                            !!selectedProduct1
                            && !!selectedProduct2
                            && !!Number(product1Qty)
                            && !!Number(product2Qty)
                            && (
                              <div
                                className={`mt-1 ${
                                  Number(offerPrice) > (
                                    (selectedProduct1.mrp * Number(product1Qty))
                                    + (selectedProduct2.mrp * Number(product2Qty))
                                  ) ? 'text-danger' : 'text-medium'
                                }`}
                              >
                                {`Should not exceed ₹${
                                  (selectedProduct1.mrp * Number(product1Qty))
                                  + (selectedProduct2.mrp * Number(product2Qty))
                                } (the sum of MRPs of the products)`}
                              </div>
                            )
                          }
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          Image:
                        </Form.Label>
                        <Col
                          sm={14}
                        >
                          {
                            offerImage ? (
                              <>
                                <img
                                  src={offerImage}
                                  alt="offer"
                                  width="100px"
                                />
                                <Button
                                  variant="outline-primary"
                                  className="ml-1"
                                  onClick={() => {
                                    this.setState({
                                      offerImage: '',
                                    });
                                  }}
                                >
                                  Change
                                </Button>
                              </>
                            ) : (
                              <input
                                type="file"
                                onChange={this.handleImageOnChange}
                              />
                            )
                          }
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          Create Story:
                        </Form.Label>
                        <Col
                          xs={4}
                          className="px-3"
                        >
                          <Form.Check
                            inline
                            label="Yes"
                            name="createStory"
                            type="radio"
                            checked={createStory}
                            onChange={this.handleRadioButtonChange}
                          />
                        </Col>
                        <Col
                          xs={4}
                          className="px-3"
                        >
                          <Form.Check
                            inline
                            label="No"
                            name="createStory"
                            type="radio"
                            checked={!createStory}
                            onChange={this.handleRadioButtonChange}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm={6} />
                        <Col sm={6}>
                          <Button
                            type="submit"
                            variant="outline-primary"
                            disabled={
                              Number(product1Qty) < 1
                              || Number(product2Qty) < 1
                              || !selectedProduct1
                              || !selectedProduct2
                              || !offerPrice
                              || !offerImage
                              || (
                                Number(offerPrice) > (
                                  (selectedProduct1.mrp * Number(product1Qty))
                                  + (selectedProduct2.mrp * Number(product2Qty))
                                )
                              )
                            }
                          >
                            SUBMIT
                          </Button>
                        </Col>
                      </Form.Group>
                    </Form>
                  </Col>
                )
            }
          </Row>
        </Container>
      );
    };

    const searchProductHeaders = [
      {
        key: 'select',
        displayText: 'Select',
        renderer: (data) => (
          <Form.Check
            type="radio"
            id="same"
            onChange={() => {
              this.setState(
                {
                  searchText: '',
                  storeProducts: null,
                  addingProduct: null,
                  [`${addingProduct === 0 ? 'selectedProduct1' : 'selectedProduct2'}`]: data,
                },
                () => {
                  const { selectedProduct1: product1 } = this.state;
                  if (product1) {
                    this.setState({
                      offerImage: product1.imageUrl,
                    });
                  }
                },
              );
            }}
          />
        ),
      },
      {
        key: 'id',
        displayText: 'Id',
      },
      {
        key: 'displayName',
        displayText: 'Product Name',
      },
      {
        key: 'sellingPrice',
        displayText: 'Price',
      },
      {
        key: 'unit',
        displayText: 'Qty.',
        renderer: (data) => (
          <span>
            {data.unit}
            {data.uom}
          </span>
        ),
      },
      {
        key: 'imageUrl',
        displayText: 'Image',
        renderer: (data) => (
          <img alt="" src={data.imageUrl} width="50px" height="50px" />
        ),
      },
    ];

    const addProductModalBody = () => {
      const { loadingProducts, productsFailure } = this.state;
      return (
        <Container>
          <Row>
            <Col
              className="px-0"
            >
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text
                    className="rounded-0"
                  >
                    <Svg
                      svg="search"
                      width="1rem"
                      fill={Constant.Color.DARK}
                    />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="search"
                  placeholder="search"
                  autoFocus
                  className="fs-1 rounded-0"
                  onChange={(e) => {
                    this.handleSubmitSearchText(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col
              xs="auto"
              className="px-0"
            >
              <Button
                className="rounded-0"
                onClick={() => {
                  this.setState({
                    searchText: '',
                    addingProduct: null,
                    storeProducts: null,
                  });
                }}
              >
                <Svg
                  svg="close"
                  width="1rem"
                  fill={Constant.Color.WHITE}
                />
              </Button>
            </Col>
          </Row>
          <Row
            fluid="true"
          >
            {
              // eslint-disable-next-line no-nested-ternary
              loadingProducts ? (
                <div
                  className="w-100 pt-3 d-flex align-items-center justify-content-center"
                >
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                </div>
              // eslint-disable-next-line no-nested-ternary
              ) : productsFailure ? (
                <div
                  className="h-100 d-flex align-items-center
                      justify-content-center"
                >
                  <ErrorHandler
                    retryLogic={() => {
                      this.handleProductLoad(searchText);
                    }}
                  />
                </div>
              ) : storeProducts !== null ? (
                <CustomTable
                  headers={searchProductHeaders}
                  content={storeProducts.results}
                  keyField="id"
                  totalItems={storeProducts.count}
                  isPaginated={false}
                />
              ) : ''
            }
          </Row>
        </Container>
      );
    };

    return (
      <Container
        fluid
        className="bg-white"
      >
        <CustomModal
          body={addBogoOfferModalBody()}
          closeButton
          size="lg"
          show={addBogoOffer}
          title="Add BOGO Offer"
          onHide={() => {
            this.setState({
              addBogoOffer: false,
              selectedProduct1: null,
              selectedProduct2: null,
              product1Qty: 1,
              product2Qty: 1,
              offerPrice: '',
              offerImage: '',
              updatedBogoName: '',
              submittingBogoOfferError: false,
              createStory: true,
            });
          }}
        />
        <CustomModal
          body={addProductModalBody()}
          show={addingProduct !== null}
          size="lg"
          onHide={() => {
            this.setState({
              searchText: '',
              storeProducts: null,
              addingProduct: null,
            });
          }}
        />
        <CustomModal
          show={!!deleteOffer}
          autoSize
          body={(
            <div
              className="p-3"
            >
              <b>Are you sure?</b>
              <div
                className="pt-1"
              >
                You should delete an offer only if you are sure that you don’t want to use it again.
              </div>
            </div>
          )}
          onHide={() => {
            if (!deletingOffer) {
              this.setState({
                deleteOffer: null,
                deletingOffer: false,
                deletingOfferError: false,
              });
            }
          }}
          footer={(
            <>
              {
                deletingOfferError && (
                  <b
                    className="text-danger"
                  >
                    Error: Please try again!!
                  </b>
                )
              }
              <Button
                variant="link"
                className="text-black"
                disabled={deletingOffer}
                onClick={() => {
                  this.setState({
                    deleteOffer: null,
                    deletingOffer: false,
                    deletingOfferError: false,
                  });
                }}
              >
                <b>GO BACK</b>
              </Button>
              {
                deletingOffer ? (
                  <Spinner
                    variant="danger-dark"
                    animation="border"
                    size="sm"
                  />
                ) : (
                  <Button
                    variant="link"
                    className="text-danger-dark"
                    onClick={() => {
                      this.handleDeleteOffer(deleteOffer);
                    }}
                  >
                    <b>DELETE OFFER</b>
                  </Button>
                )
              }
            </>
          )}
        />
        <Row>
          <Col
            xs="auto"
            className={`p-3 ${this.canIEdit ? '' : 'd-none'}`}
          >
            <Button
              variant="outline-primary"
              onClick={() => {
                this.setState({
                  addBogoOffer: true,
                });
              }}
            >
              ADD BOGO OFFER
            </Button>
          </Col>
          <Col
            xs="auto"
            className="p-3 d-flex"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="search"
                placeholder="Search Offers"
                className="fs-1 rounded-0"
                id="offersSearchText"
                value={offersSearchText}
                onChange={this.handleInputOnChange}
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.handleSearch('search');
                  }
                }}
              />
            </InputGroup>
            <Button
              onClick={() => this.handleSearch()}
              variant="primary"
              disabled={view !== 'search'}
            >
              X
            </Button>
          </Col>
        </Row>
        <Row>
          {offers && (
            <Col
              xs={24}
              className="pb-3"
            >
              {offerLists}
            </Col>
          )}
        </Row>
        <Row>
          <Col
            xs={24}
            className="text-center"
          >
            {offers && offers.data.length === 0 && (
            <h6
              className="p-3 text-danger"
            >
              Not Available any Buy1Get1 Offer
            </h6>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

BogoOffer.propTypes = {
  retailerDetails: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default BogoOffer;
