/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import CustomTable from '../../component/common/CustomTable';
import { shopAllocation, genericBulkUpload, retailersAllocationTeam } from '../../assets/api/axios';
import Permission from '../../access&permissions/permission';

class ShopAllocation extends Component {
  constructor(props) {
    super(props);
    const { userPermission } = props;
    this.canIEdit = userPermission
      && userPermission.includes(Permission.SHOP_ALLOCATION_WRITE);
    this.state = {
      selectedTab: this.canIEdit ? 'upload' : 'list',
      shopAllocHistory: [],
      loadStatus: '',
      page: 1,
      rowsPerPage: 20,
      count: 0,
      hasNext: false,
      hasPrevious: false,
      storeId: '',
      searchText: '',
      file: null,
      uploadStatus: '',
      teamId: '',
      teamFilters: [],
    };
  }

  componentDidMount = () => {
    this.getShopAllocHistory();
  }

  getTeamFilters() {
    retailersAllocationTeam('GET')
      .then((res1) => {
        this.setState({
          teamFilters: res1.data.data.allocationTeams,
        });
      })
      .catch((err) => err);
  }

  handleSwitchtab = (tab) => {
    const { selectedTab } = this.state;
    if (selectedTab !== tab) {
      this.setState({
        selectedTab: tab,
      }, () => {
        this.getShopAllocHistory();
      });
    }
  }

  shopAllocationApiCall = () => {
    const {
      page, storeId, searchText, teamId, rowsPerPage,
    } = this.state;
    const offset = (page - 1) * rowsPerPage;
    this.setState({ loadStatus: 'loading' });
    shopAllocation('GET', {}, storeId, searchText, teamId, offset, rowsPerPage, true)
      .then((res) => {
        this.setState({
          shopAllocHistory: res.data.results,
          count: res.data.count,
          hasNext: res.data.hasNext,
          hasPrevious: res.data.hasPrev,
          loadStatus: 'success',
        });
      })
      .catch(() => {
        this.setState({
          loadStatus: 'error',
        });
      });
  }

  getShopAllocHistory = () => {
    const { selectedTab } = this.state;
    if (selectedTab === 'list') {
      this.shopAllocationApiCall();
      this.getTeamFilters();
    }
  }

  onNext = () => {
    const { page } = this.state;
    this.setState({
      page: page + 1,
    }, this.shopAllocationApiCall);
  }

  onPrev = () => {
    const { page } = this.state;
    this.setState({
      page: page - 1,
    }, this.shopAllocationApiCall);
  }

  onSubmitPage = () => {
    const { page } = this.state;
    this.setState({
      page,
    }, this.shopAllocationApiCall);
  }

  onSubmitRowsPerPage = () => {
    const { rowsPerPage } = this.state;
    this.setState({
      rowsPerPage,
    }, this.shopAllocationApiCall);
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  handleBulkUpload = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  }

  handleSubmit = () => {
    const { file } = this.state;
    const formData = new FormData();
    formData.append('data', file);
    formData.append('uploadType', 'SHOPALLOCATION');
    this.setState({ uploadStatus: 'loading' });
    genericBulkUpload(
      'POST',
      formData,
      {
        schema: true,
        uploadType: 'SHOPALLOCATION',
      },
    )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            uploadStatus: 'success',
          }, () => {
            setTimeout(() => {
              this.setState({
                uploadStatus: '',
              });
            }, 3000);
          });
        }
      })
      .catch(() => {
        this.setState({
          uploadStatus: 'error',
        });
      });
  }

  render() {
    const {
      selectedTab, loadStatus, shopAllocHistory, count,
      uploadStatus, file, teamId, storeCode, hasNext,
      hasPrevious, rowsPerPage, page, teamFilters,
    } = this.state;
    const config = [
      {
        key: 'storeCode',
        displayText: 'Code',
      },
      {
        key: 'storeName',
        displayText: 'Shop Name',
      },
      {
        key: 'allocationTeamId',
        displayText: 'Team #',
      },
      {
        key: 'created_on',
        displayText: 'Assigned on',
        renderer: (data) => new Date(data.created_on).toLocaleString(),
      },
      {
        key: 'status',
        displayText: 'Status',
      },
      // {
      //   key: 'action',
      //   displayText: 'Action',
      // },
    ];

    return (
      <Container
        fluid
        className="px-0 bg-white"
      >
        <Row>
          <Col
            xs={12}
            className={`text-center px-0 py-1 border
              border-secondary bg-white ${this.canIEdit ? '' : 'd-none'}`}
          >
            <Button
              variant="link"
              block
              className={
                selectedTab === 'upload'
                  ? 'text-primary'
                  : 'text-dark'
              }
              onClick={() => this.handleSwitchtab('upload')}
            >
              <b>Shop Allocation</b>
            </Button>
          </Col>
          <Col
            xs={12}
            className="text-center px-0 py-1 border border-secondary bg-white"
          >
            <Button
              variant="link"
              block
              className={
                selectedTab === 'list'
                  ? 'text-primary'
                  : 'text-dark'
              }
              onClick={() => this.handleSwitchtab('list')}
            >
              <b>Shop Allocation History</b>
            </Button>
          </Col>
        </Row>
        {selectedTab === 'upload' ? (
          <Row className="px-3">
            <Col
              xs={24}
              className="text-center mt-3 p-2 border"
            >
              <input
                type="file"
                accept={
                  `.csv,
                  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                  application/vnd.ms-excel`
                }
                onChange={this.handleBulkUpload}
              />
            </Col>
            <Col
              xs={24}
              className="text-center mt-3 p-2 border"
            >
              <Button
                variant="light"
                className="border border-dark py-1 px-4"
                disabled={uploadStatus === 'loading' || !file}
                onClick={this.handleSubmit}
              >
                {uploadStatus === 'loading' && (
                  <Spinner variant="secondary" animation="border" />
                )}
                &nbsp;&nbsp;SUBMIT&nbsp;&nbsp;
              </Button>
            </Col>
            {uploadStatus === 'success' && (
              <Col xs={24} className="text-center py-3 fs-2 text-success">
                <b>File Uploaded Successfully!!</b>
              </Col>
            )}
            {uploadStatus === 'error' && (
              <Col xs={24} className="text-center py-3 fs-2 text-danger">
                <b>Oop&#39;s Something went wrong</b>
              </Col>
            )}
          </Row>
        ) : (
          <Row>
            <Col
              xs={6}
              className="pt-3 pl-6 text-center"
            >
              <input
                type="text"
                placeholder="Search By Code or Shop Name"
                className="form-control w-100 shadow-sm"
                value={storeCode}
                onChange={(event) => {
                  this.setState({
                    searchText: event.target.value,
                  });
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter' || event.keyCode === 13) {
                    this.setState({
                      searchText: event.target.value,
                    }, this.shopAllocationApiCall);
                  }
                }}
              />
            </Col>
            <Col
              xs={6}
              className="pt-3 pr-6 text-center"
            >
              <select
                className="form-control w-100 shadow-sm"
                value={teamId}
                onChange={(event) => {
                  this.setState({ teamId: event.target.value });
                }}
              >
                <option value="">Select Team Id</option>
                {teamFilters.map((filter) => (
                  <option value={filter}>{filter}</option>
                ))}
              </select>
            </Col>
            <Col
              xs={6}
              className="pt-3"
            >
              <Button
                variant="outline-primary"
                onClick={() => {
                  this.shopAllocationApiCall();
                }}
              >
                Filter
              </Button>
            </Col>
            <Col
              xs={24}
              className="text-center py-3"
            >
              {loadStatus === 'loading' ? (
                <Spinner
                  animation="border"
                  variant="primary"
                />
              ) : loadStatus === 'error' ? (
                <span className="fs-1 text-danger">
                  <b>Oop&#39;s Something went wrong</b>
                </span>
              ) : (
                <CustomTable
                  headers={config}
                  content={shopAllocHistory}
                  keyField="id"
                  striped
                  bordered
                  hover
                  size="sm"
                  responsive="md"
                  variant="white"
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={count}
                  hasPrev={hasPrevious}
                  hasNext={hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              )}
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}


ShopAllocation.propTypes = {
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default ShopAllocation;
