import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Form, InputGroup, Button, Spinner,
} from 'react-bootstrap';
import Axios from 'axios';
import { Svg, CustomModal } from '../../component/common';
import { Constant } from '../../utilities';
import {
  libraryProduct, retailerProducts,
} from '../../assets/api/axios';
import { Backdrop } from '../wrapper';
import '../../assets/scss/RetailerManagement/AddNewProduct.scss';
import permission from '../../access&permissions/permission';

class AddNewProduct extends React.Component {
  constructor(props) {
    super();
    const { userPermission } = props;
    this.state = {
      query: '',
      loading: false,
      error: false,
      limit: 10,
      selectedProduct: null,
      addedProducts: [],
      products: null,
    };
    this.isForceAdd = userPermission.includes(permission.FORCE_ADD_BULK_UPLOAD);
    this.source = Axios.CancelToken.source();
  }

  onSubmitSearchText = (e) => {
    const { value } = e.target;
    const { loading, query } = this.state;
    if (value !== query) {
      if (loading) {
        this.source.cancel();
        this.source = Axios.CancelToken.source();
      }
      if (value) {
        this.setState({
          loading: true,
          query: value,
        }, () => {
          this.handleLoad();
        });
        return;
      }
      this.setState({
        loading: false,
        products: null,
      });
    }
  }

  handleLoad = () => {
    const { limit, query } = this.state;
    const { retailerCode } = this.props;
    libraryProduct('get',
      null,
      null,
      {
        page: 1,
        limit: `${limit}`,
        searchText: `${query}`,
        storeId: retailerCode,
        ignoreAddedProduct: true,
      },
      this.source.token)
      .then((res) => {
        this.setState({
          loading: false,
          products: { ...res.data },
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
          products: null,
        });
      });
  }

  addProduct = () => {
    const { selectedProduct, addedProducts } = this.state;
    const { retailerCode } = this.props;
    retailerProducts('post', {
      libraryProductId: selectedProduct,
    }, retailerCode)
      .then((res) => {
        const StoreProductID = res.data.data;
        if (StoreProductID) {
          const { id, mrp, sellingPrice } = StoreProductID;
          this.setState({
            addedProducts: [
              ...addedProducts,
              {
                libraryProductId: selectedProduct,
                id,
                mrp,
                sellingPrice,
              },
            ],
            selectedProduct: null,
          });
        }
      })
      .catch(() => {
      });
  }

  selectProduct = (productId) => {
    this.setState({
      selectedProduct: productId,
    }, () => {
      this.addProduct();
    });
  }

  render() {
    const {
      products, loading, selectedProduct, addedProducts, error,
    } = this.state;
    const { show, toggleAddNewProductView } = this.props;
    const body = (
      <Container
        className="mx-0"
      >
        <Row>
          <Col
            xs={24}
            className="px-0 mb-2"
          >
            <Row
              className="mx-0"
            >
              <Col
                className="px-0"
              >
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className="rounded-0"
                    >
                      <Svg
                        svg="search"
                        width="1rem"
                        fill={Constant.Color.DARK}
                      />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    className="fs-1 rounded-0"
                    autoComplete="off"
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        this.onSubmitSearchText(e);
                      }
                    }}
                  />
                </InputGroup>
              </Col>
              <Col
                xs="auto"
                className="px-0"
              >
                <Button
                  variant="dark"
                  className="rounded-0"
                  onClick={() => {
                    this.setState({
                      products: null,
                    }, toggleAddNewProductView);
                  }}
                >
                  <Svg
                    svg="close"
                    width="1rem"
                    fill={Constant.Color.WHITE}
                  />
                </Button>
              </Col>
            </Row>
          </Col>
          <Col
            xs={24}
            className="px-2"
          >
            {products && products.data.map((product) => {
              const isSelected = selectedProduct === product.productId;
              const retailerProduct = addedProducts.find((productItems) => (
                productItems.libraryProductId === product.productId));
              return (
                <Row
                  key={product.productId}
                  className="my-2 mx-0"
                >
                  <Col
                    xs={24}
                    className="px-0"
                  >
                    <Button
                      variant="white"
                      className="fs-0 d-block w-100 rounded-0 border text-left"
                      disabled={!!retailerProduct}
                      onClick={() => {
                        this.selectProduct(product.productId);
                      }}
                    >
                      <Row
                        className="mx-0"
                      >
                        <Col
                          className="p-2 fs-0"
                        >
                          <div>
                            {product.name}
                          </div>
                          {retailerProduct && (
                          <div
                            className="mt-2"
                          >
                            <span
                              className="mr-2 text-primary"
                            >
                              &#8377;
                              {retailerProduct.sellingPrice}
                            </span>
                            <span>
                              &#8377;
                              {retailerProduct.mrp}
                            </span>
                          </div>
                          )}
                        </Col>
                        <Col
                          xs="auto"
                          className="p-2"
                        >
                          <div
                            className="product-image-div"
                          >
                            <img
                              src={product.image_url}
                              alt=""
                            />
                          </div>
                        </Col>
                      </Row>
                    </Button>
                    {isSelected && (
                    <Backdrop
                      show
                      onFade={() => {
                        this.setState({
                          selectedProduct: null,
                        });
                      }}
                    />
                    )}
                  </Col>
                </Row>
              );
            })}
          </Col>
          <Col
            xs={24}
          >
            <div
              className="text-center"
            >
              {
                loading && (
                <Spinner
                  animation="border"
                  variant="primary"
                />
                )
              }
              {
                !loading && error && (
                  <div
                    className="pt-2 text-center text-danger"
                  >
                    <Col
                      xs={24}
                    >
                      Oops! Something went wrong.&nbsp;
                    </Col>
                    <Col
                      xs={24}
                      className="pt-2"
                    >
                      <Button
                        variant="outline-primary"
                        className="py-1"
                        onClick={() => {
                          this.setState({
                            loading: true,
                            error: false,
                          }, () => this.handleLoad());
                        }}
                      >
                        Retry
                      </Button>
                    </Col>
                  </div>
                )
              }
              {products?.next && (
              <Button
                className="fs-01"
                onClick={() => {}}
              >
                Load More
              </Button>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    );
    return (
      <CustomModal
        show={show}
        body={body}
        onHide={() => {
          this.setState({
            query: '',
            loading: false,
            error: false,
            products: null,
          });
        }}
      />
    );
  }
}

AddNewProduct.propTypes = {
  show: PropTypes.bool.isRequired,
  retailerCode: PropTypes.string.isRequired,
  toggleAddNewProductView: PropTypes.func.isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default AddNewProduct;
