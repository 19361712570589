import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Container, Row, Col,
} from 'react-bootstrap';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { deliverySettings } from '../../assets/api/axios';
import { Constant } from '../../utilities';
import { Svg } from '../../component/common';

const EditBusinessTiming = ({
  id, businessTimings, businessTimingsInstance, onClose, onSave, storeCode,
}) => {
  const [timingData, setTimingData] = useState(null);
  const [editedSlots, setEditedSlots] = useState([]);
  const [isClosed, setIsClosed] = useState(businessTimingsInstance?.isClosed || false);
  const [slots, setSlots] = useState(businessTimingsInstance?.slots || []);
  const [setForAllDays, setSetForAllDays] = useState(false);
  const [errorMsgInfo, setErrorMsgInfo] = useState({});
  const [updatingStatus, setUpdatingStatus] = useState('');

  const convertToDate = (time, date = '2024-07-23') => moment(`${date} ${time}`, 'YYYY-MM-DD hh:mm A').toDate();

  const convertTime12HrsFormat = (time) => moment(time).format('hh:mm A');

  const convertToEpoch = (date) => {
    if (!date) return null;
    return moment(date).valueOf();
  };

  const compareTimes = (time1, time2) => moment(time1, 'hh:mm A').diff(moment(time2, 'hh:mm A'));

  useEffect(() => {
    const data = businessTimings.find((timing) => timing.id === id);
    if (data) {
      setTimingData(data);
      setEditedSlots(data.slots.map((slot) => ({
        ...slot,
        startTime: convertTime12HrsFormat(slot.startTime),
        endTime: convertTime12HrsFormat(slot.endTime),
      })));
    }
  }, [id, businessTimings]);

  const handleSlotChange = (index, key, value) => {
    setEditedSlots((prevSlots) => {
      const updatedSlots = [...prevSlots];
      updatedSlots[index] = { ...updatedSlots[index], [key]: value };
      return updatedSlots;
    });
  };

  const handleSave = () => {
    const tempErrors = {};

    //  Validate slot times
    editedSlots.forEach((slot, index) => {
      if (compareTimes(slot.endTime, slot.startTime) <= 0) {
        tempErrors[`error-${index}`] = {
          errorText: '*The closing time must be after the opening time.',
        };
      }
    });

    editedSlots.forEach((slotInstance1, index1) => {
      editedSlots.forEach((slotInstance2, index2) => {
        if (index1 !== index2) {
          const start1 = slotInstance1.startTime;
          const end1 = slotInstance1.endTime;
          const start2 = slotInstance2.startTime;
          const end2 = slotInstance2.endTime;

          const start1vsStart2 = compareTimes(start1, start2);
          const start1vsEnd2 = compareTimes(start1, end2);
          const end1vsStart2 = compareTimes(end1, start2);
          const end1vsEnd2 = compareTimes(end1, end2);

          if (
            (start1vsStart2 >= 0 && start1vsStart2 < 12 && start1vsEnd2 <= 0)
            || (end1vsStart2 > 0 && end1vsEnd2 <= 0)
            || (start1vsStart2 <= 0 && end1vsEnd2 >= 0)
          ) {
            tempErrors[`error-${index1}`] = {
              errorText: '*The selected time slot overlaps with an existing slot. Please choose a different time.',
            };
          }
        }
      });
    });

    setErrorMsgInfo(tempErrors);

    if (Object.keys(tempErrors).length) {
      setUpdatingStatus('');
      return;
    }

    const updatedSlots = editedSlots.map((slot) => ({
      ...slot,
      startTime: convertToEpoch(convertToDate(slot.startTime)),
      endTime: convertToEpoch(convertToDate(slot.endTime)),
    }));

    let payload;

    if (setForAllDays) {
      payload = {
        businessTimings: [{
          id: -1,
          displayText: businessTimingsInstance?.displayText,
          isClosed: false,
          slots: updatedSlots,
        }],
      };
    } else {
      payload = {
        businessTimings: [{
          id: businessTimingsInstance?.id,
          displayText: businessTimingsInstance?.displayText,
          isClosed,
          slots: updatedSlots,
        }],
      };
    }
    setUpdatingStatus('Saving...');

    deliverySettings('patch', storeCode, payload)
      .then((res) => {
        if (res.status === 200) {
          // eslint-disable-next-line no-use-before-define
          refreshData();
        }
      })
      .catch((err) => {
        console.error('Error updating data', err);
        setUpdatingStatus('');
      });
  };

  const refreshData = () => {
    deliverySettings('get', storeCode)
      .then((res) => {
        if (res.status === 200) {
          onSave(res.data.data.businessTimings);
        }
      })
      .catch((err) => {
        console.error('Error fetching data', err);
      });
  };

  if (!timingData) return null;

  const addSlot = () => {
    const openingTime = '09:00 AM';
    const closingTime = '09:00 PM';
    const newSlot = {
      startTime: openingTime,
      endTime: closingTime,
    };
    setEditedSlots([...editedSlots, newSlot]);
  };

  const deleteSlot = (index) => {
    const tempErrors = {};
    if (editedSlots.length === 1) {
      tempErrors[`error-${index}`] = {
        errorText: '*Selection of at least one slot is mandatory.',
      };
      setErrorMsgInfo(tempErrors);
      return;
    }
    const temp = [...editedSlots];
    temp.splice(index, 1);
    setEditedSlots(temp);
  };

  const renderSlot = (slotInstance, index) => (
    <div>
      <div
        className="time-picker-container"
        style={{ opacity: isClosed ? '0.3' : '1' }}
      >
        <>
          <Row>
            <Col className="d-flex align-items-center">
              <div>
                <b>{`Slot ${index + 1}`}</b>
              </div>
            </Col>
            <Col className="text-right">
              <Button
                variant="outline"
                style={{ backgroundColor: isClosed ? 'background-color' : 'transparent' }}
                onClick={() => deleteSlot(index)}
                disabled={isClosed}
              >
                <Svg
                  svg="trash"
                  width="1.2rem"
                  stroke={isClosed ? Constant.Color.PRIMARY : Constant.Color.RED}
                />
              </Button>
            </Col>
          </Row>
          <Row key={index} className="mb-3 mt-2">

            <Col>
              <span className="fs-1">Opening Time:</span>
              <TimePicker
                format="hh:mm a"
                value={moment(slotInstance.startTime, 'hh:mm A')}
                onChange={(value) => handleSlotChange(index, 'startTime', value && value.format('hh:mm A'))}
                use12Hours
                inputReadOnly
                showSecond={false}
                disabled={isClosed}
                allowEmpty={false}
              />
            </Col>

            <Col>
              <span>Closing Time:</span>
              <TimePicker
                format="hh:mm a"
                value={moment(slotInstance.endTime, 'hh:mm A')}
                onChange={(value) => handleSlotChange(index, 'endTime', value && value.format('hh:mm A'))}
                use12Hours
                inputReadOnly
                showSecond={false}
                disabled={isClosed}
                allowEmpty={false}
              />
            </Col>
          </Row>
        </>
      </div>
      <Row>
        <Col>
          {errorMsgInfo[`error-${index}`] && (
            <p className="text-danger">{errorMsgInfo[`error-${index}`].errorText}</p>
          )}
        </Col>
      </Row>
    </div>
  );
  return (
    <>
      <Container className="edit-business-timing-container mt-2 p-3">
        <h3 className="fs-1">
          <b>
            Edit hours for&nbsp;
            {`${timingData.displayText}`}
          </b>
        </h3>
        <Form className="mt-4 mb-3 custom-checkbox d-flex">
          <Form.Check
            type="checkbox"
            label="Set for all days"
            checked={setForAllDays}
            onChange={() => setSetForAllDays(!setForAllDays)}
            disabled={isClosed}
          />
          <Form.Check
            className="ml-6"
            type="checkbox"
            label="Closed"
            checked={isClosed}
            onChange={() => setIsClosed(!isClosed)}
          />
        </Form>

        {editedSlots.map((s, i) => renderSlot(s, i))}
        <Button style={{ backgroundColor: isClosed ? 'background-color' : 'transparent' }} className="d-flex plus-icon" variant="outline" onClick={addSlot} disabled={isClosed}>
          <div style={{ opacity: isClosed ? '0.3' : '1' }} className="d-flex align-items-center">
            <span style={{ fontSize: '23px' }}>+</span>
          &nbsp;Add Slots
          </div>
        </Button>
      </Container>
      <div className="sticky-buttons">
        <Button variant="secondary" onClick={onClose} className="ml-2">
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={updatingStatus === 'Saving...'}>
          {updatingStatus === 'Saving...' ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </>
  );
};

EditBusinessTiming.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  businessTimings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    displayText: PropTypes.string.isRequired,
    isClosed: PropTypes.bool.isRequired,
    slots: PropTypes.arrayOf(PropTypes.shape({
      startTime: PropTypes.number.isRequired,
      endTime: PropTypes.number.isRequired,
    })).isRequired,
  })).isRequired,
  businessTimingsInstance: PropTypes.shape({
    id: PropTypes.number,
    displayText: PropTypes.string,
    isClosed: PropTypes.bool,
    slots: PropTypes.arrayOf(PropTypes.shape({
      startTime: PropTypes.number,
      endTime: PropTypes.number,
    })),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  storeCode: PropTypes.string.isRequired,
};

export default EditBusinessTiming;
