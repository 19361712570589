import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import Priviledge from '../access&permissions/priviledge';

function Dashboard(props) {
  const { history, userPriviledge } = props;
  const {
    RETAILER,
    SHOP_ALLOCATION,
    ORDERS,
    // PLACE_ORDER,
    DELIVERY_SETTING,
    BULK_COUPON_CREATION,
    REVIEW_PANEL,
    LL_DAILY,
    BANNERS,
    SHARE_OFFER_CARD,
    SMS_SENT,
    PENDING_SETTLEMENTS,
    RECONCILED_SETTLEMENTS,
    PENDING_SETTLEMENTS_STORE,
    FEEDS,
    GENERIC_BULK_UPLOAD,
    COUPONS,
    COUPON_TRACKER,
    REFERRAL_CHECK,
    PRICE_UPDATE,
    PRODUCT_ADD,
    STORE_WISE_JOB_STATUS,
    SHOP_LOGO_REVIEW,
    RETAILER_STORY_REVIEW,
    FLASH_SALE_DETAILED,
    FLASH_SALE_AGGREGATED,
    STORIES,
    CUSTOMER,
    REFUND_DASHBOARD,
    QUERY_TRACKER,
    SMART_CATALOGUES,
    SMART_CATALOGUE_MAPPINGS,
    LIBRARY_PRODUCTS,
    LEAD_MANAGEMENT,
    EXPORT_CLEANUP_TOOL,
    CUSTOMER_PANEL,
    FRAUD_MANAGEMENT,
    OM_STATUS_TRACKER,
    STORE_RESURRECTION,
    LEADERSHIP_BOARD,
    CAMPAIGN_ANALYTICS,
    CART_ABANDON_USER,
    COUPON_ENGINE,
    CX_RX_CALL_MAPPING,
    BRAND_SAMPLING_CAMPAIGN,
    MARKETPLACE_BRANDS,
    MARKETPLACE_ORDERS,
    BRAND_BANNERS,
    FESTIVE_CATEGORIES,
    FINANCE_SETTLEMENT_DASHBOARD,
    PLATFORM_FEE_DASHBOARD,
    FLASH_SALE_CAMPAIGN,
    STORE_MANAGEMENT,
    LL_PLUS_PURCHASES,
    SPECIAL_NOTES,
  } = Priviledge;

  const provision = [
    {
      key: RETAILER,
      label: 'Retailer',
      path: '/retailer',
      text: 'primary',
    },
    {
      key: ORDERS,
      label: 'Orders',
      path: '/orders',
      text: 'primary',
    },
    {
      key: SHOP_ALLOCATION,
      label: 'Shop Allocation',
      path: '/shop-allocation',
      text: 'primary',
    },
    // {
    //   key: PLACE_ORDER,
    //   label: 'Place Order',
    //   path: '/place-order',
    //   text: 'primary',
    // },
    {
      key: DELIVERY_SETTING,
      label: 'Delivery Setting',
      path: '/retailer-setting',
      text: 'primary',
    },
    {
      key: BULK_COUPON_CREATION,
      label: 'Coupon Creation',
      path: '/coupon-creation',
      text: 'primary',
    },
    {
      key: REVIEW_PANEL,
      label: 'Review Panel',
      path: '/review-panel',
      text: 'primary',
    },
    {
      key: LL_DAILY,
      label: 'LoveLocal Daily',
      path: '/lovelocal-daily',
      text: 'primary',
    },
    {
      key: BANNERS,
      label: 'Banners',
      path: '/banners',
      text: 'primary',
    },
    {
      key: SHARE_OFFER_CARD,
      label: 'Share Offer Card',
      path: '/share-offer-card',
      text: 'primary',
    },
    {
      key: SMS_SENT,
      label: 'Sms Sent',
      path: '/sms-sent',
      text: 'primary',
    },
    {
      key: PENDING_SETTLEMENTS,
      label: 'Pending Settlements',
      path: '/pending-settlements?status=PENDING',
      text: 'success',
    },
    {
      key: PENDING_SETTLEMENTS_STORE,
      label: 'Pending Settlements (Store)',
      path: '/pending-settlements-store',
      text: 'success',
    },
    {
      key: RECONCILED_SETTLEMENTS,
      label: 'Reconciled Settlements',
      path: '/reconciled-settlements',
      text: 'success',
    },
    {
      key: REFUND_DASHBOARD,
      label: 'Refund Dashboard',
      path: '/refund-dashboard',
      text: 'success',
    },
    {
      key: FEEDS,
      label: 'Retailer Feeds',
      path: '/feeds-section',
      text: 'primary',
    },
    {
      key: GENERIC_BULK_UPLOAD,
      label: 'Generic Bulk Upload',
      path: '/generic-bulk-upload',
      text: 'primary',
    },
    {
      key: COUPONS,
      label: 'Coupons',
      path: '/coupon',
      text: 'primary',
    },
    {
      key: COUPON_TRACKER,
      label: 'Coupon Tracker',
      path: '/coupon-tracker',
      text: 'primary',
    },
    {
      key: QUERY_TRACKER,
      label: 'Query Tracker',
      path: '/query-tracker',
      text: 'primary',
    },
    {
      key: CUSTOMER_PANEL,
      label: 'Customer Panel',
      path: '/customer-panel',
      text: 'primary',
    },
    {
      key: REFERRAL_CHECK,
      label: 'Referral Check',
      path: '/referral-check',
      text: 'primary',
    },
    {
      key: PRICE_UPDATE,
      label: 'Price Update',
      path: '/price-update',
      text: 'primary',
    },
    {
      key: PRODUCT_ADD,
      label: 'Product Add',
      path: '/product-add',
      text: 'primary',
    },
    {
      key: STORE_WISE_JOB_STATUS,
      label: 'Store Job Status',
      path: '/store-wise-job-status',
      text: 'primary',
    },
    {
      key: SHOP_LOGO_REVIEW,
      label: 'Shop Logo Review',
      path: 'shop-logo-review',
      text: 'primary',
    },
    {
      key: RETAILER_STORY_REVIEW,
      label: 'Retailer Story Review',
      path: '/retailer-story-review',
      text: 'primary',
    },
    {
      key: FLASH_SALE_AGGREGATED,
      label: 'Flash Sale (Aggregated)',
      path: 'flash-sale-aggregated',
      text: 'primary',
    },
    {
      key: FLASH_SALE_DETAILED,
      label: 'Flash Sale (Detailed)',
      path: 'flash-sale-detailed',
      text: 'primary',
    },
    {
      key: STORIES,
      label: 'Stories',
      path: '/stories',
      text: 'primary',
    },
    {
      key: CUSTOMER,
      label: 'Customer',
      path: '/customer',
      text: 'primary',
    },
    {
      key: SMART_CATALOGUES,
      label: 'Smart Catalogues',
      path: '/smart-catalogues',
      text: 'primary',
    },
    {
      key: SMART_CATALOGUE_MAPPINGS,
      label: 'Smart Catalogue Mappings',
      path: '/smart-catalogue-map',
      text: 'primary',
    },
    {
      key: LIBRARY_PRODUCTS,
      label: 'Library Products',
      path: '/library-products',
      text: 'primary',
    },
    {
      key: LEAD_MANAGEMENT,
      label: 'Lead Management',
      path: '/lead-management',
      text: 'primary',
    },
    {
      key: EXPORT_CLEANUP_TOOL,
      label: 'Export Cleanup Tool',
      path: '/export-cleanup-tool',
      text: 'primary',
    },
    {
      key: FRAUD_MANAGEMENT,
      label: 'Fraud Management',
      path: '/fraud-management',
      text: 'primary',
    },
    {
      key: OM_STATUS_TRACKER,
      label: 'OM Status Tracker',
      path: '/om-status-tracker',
      text: 'primary',
    },
    {
      key: STORE_RESURRECTION,
      label: 'Store Resurrection',
      path: '/store-resurrection',
      text: 'primary',
    },
    {
      key: LEADERSHIP_BOARD,
      label: 'Leadership Board',
      path: '/leadership-board',
      text: 'primary',
    },
    {
      key: CAMPAIGN_ANALYTICS,
      label: 'Campaign Analytics',
      path: '/campaign-analytics',
      text: 'primary',
    },
    {
      key: CART_ABANDON_USER,
      label: 'Cart Abandon Users',
      path: '/cart-abandon-users',
      text: 'primary',
    },
    {
      key: COUPON_ENGINE,
      label: 'Coupon Engine',
      path: '/campaign',
      text: 'primary',
    },
    {
      key: CX_RX_CALL_MAPPING,
      label: 'CX / RX Call Mapping',
      path: '/cx-rx-call-mapping',
      text: 'primary',
    },
    {
      key: BRAND_SAMPLING_CAMPAIGN,
      label: 'Brand Sampling Campaign',
      path: '/brand-sampling-campaign-management',
      text: 'primary',
    },
    {
      key: MARKETPLACE_BRANDS,
      label: 'Marketplace Brands',
      path: '/marketplace-brands',
      text: 'primary',
    },
    {
      key: MARKETPLACE_ORDERS,
      label: 'Marketplace Orders',
      path: '/marketplace-orders',
      text: 'primary',
    },
    {
      key: BRAND_BANNERS,
      label: 'Brand Banners',
      path: '/brand-banners',
      text: 'primary',
    },
    {
      key: FESTIVE_CATEGORIES,
      label: 'Festive Categories',
      path: '/festive-categories',
      text: 'primary',
    },
    {
      key: FINANCE_SETTLEMENT_DASHBOARD,
      label: 'Finance Dashboard',
      path: '/finance-settlement-dashboard',
      text: 'primary',
    },
    {
      key: PLATFORM_FEE_DASHBOARD,
      label: 'Platform Fee Tracker',
      path: '/platform-fee-dashboard',
      text: 'primary',
    },
    {
      key: FLASH_SALE_CAMPAIGN,
      label: 'Flash Sale Campaign',
      path: '/flash-sale-campaign',
      text: 'primary',
    },
    {
      key: STORE_MANAGEMENT,
      label: 'Store Management',
      path: '/store-management',
      text: 'primary',
    },
    {
      key: LL_PLUS_PURCHASES,
      label: 'LL Plus Purchases',
      path: '/ll-plus-purchases',
      text: 'primary',
    },
    {
      key: SPECIAL_NOTES,
      label: 'Special Notes',
      path: '/special-notes',
      text: 'primary',
    },
  ];

  return (
    <div
      className="h-100 bg-white overflow-y"
    >
      <Container
        className="mx-auto"
      >
        <Row
          className="align-items-center
          justify-content-around py-4"
        >
          {provision.filter((item) => userPriviledge.includes(item.key))
            .map((item) => (
              <Col
                key={item.path}
                xs={12}
                md={8}
                className="py-4"
              >
                <Button
                  variant="white"
                  className={`text-center text-${item.text} w-100 fs-1 shadow py-4 border-0 rounded-0`}
                  onClick={() => {
                    history.push(item.path);
                  }}
                >
                  <b>{item.label}</b>
                </Button>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
}

Dashboard.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  userPriviledge: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default Dashboard;
